// Instant Tab.js
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { addHours } from "date-fns";

import ModelDropDown from "./ModelDropDown";
import ParameterDropDown from "./ParameterDropDown";
import ModelStatistics from "./ModelStats";
import DatePickerComponent from "./DatePickerComponent";
import DatePickerControlsComponent from "./DatePickerControlsComponent";
import DataStats from "./DataStats";
import ColorRamp from "./ColorRamp";
import OpacitySlider from "./OpacitySlider/OpacitySlider";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerStyles.css";
import styles from "../SideForm.module.css";

const InstantTab = ({
  register,
  control,
  handleSubmit,
  onSubmit,
  errors,
  setValue,
  selectedModel,
  setSelectedModel,
  modelRun,
  setModelRun,
  allModels,
  forecastHour,
  tiffStats,
  now,
  activeTabKey,
  loading,
  selectedDate,
  setSelectedDate,
  setParameter,
  setSubmitted,
  parameter,
  setRampType,
  setRampScale,
  rampType,
  rampScale,
  setColorBlind,
  opacity,
  setOpacity,
  increment,
  setIncrement,
  incrementUnit,
  setIncrementUnit,
}) => {
  const [isLocalTime, setIsLocalTime] = useState(true); // true for UTC, false for local

  const extractDateFromModelRun = (modelRun) => {
    const dateMatch = modelRun.match(/(\d{4}-\d{2}-\d{2})/);
    const timeMatch = modelRun.match(/\((\d{2})Z\)/);

    if (dateMatch && timeMatch) {
      const [year, month, day] = dateMatch[1].split("-").map(Number);
      const hour = Number(timeMatch[1]);

      // Create a new Date object in UTC
      return new Date(Date.UTC(year, month - 1, day, hour));
    }

    return null;
  };

  const modelRunDate = extractDateFromModelRun(modelRun);

  useEffect(() => {
    // Default increment to 1
    setIncrement(1);
  
    if (selectedModel === "era5_monthly") {
      setIncrementUnit("months");
      if (modelRunDate) {
        let defaultDate = new Date(modelRunDate);
        defaultDate.setMonth(defaultDate.getMonth() - 1); // Adjust to 1 month earlier
        setSelectedDate(defaultDate);
        setValue("start_date", defaultDate);
      }
    } else if (selectedModel === "reanalysis_season") {
      setIncrementUnit("years");
    } else if (
      selectedModel === "gfs_daily" ||
      selectedModel === "reanalysis_24" ||
      selectedModel === "era5_daily"
    ) {
      setIncrementUnit("days");
      // Set the default date of the date picker
      if (modelRunDate) {
        let defaultDate = new Date(modelRunDate);
        if (selectedModel === "reanalysis_24") {
          defaultDate.setDate(defaultDate.getDate() - 1); // Adjust to 1 day earlier
        } else if (selectedModel === "era5_daily") {
          defaultDate.setDate(defaultDate.getDate() - 6); // Adjust to 6 days earlier
        } else if (selectedModel === "gfs_daily") {
          defaultDate.setDate(defaultDate.getDate() + 1); // Adjust to 1 day later
        }
        setSelectedDate(defaultDate);
        setValue("start_date", defaultDate);
      }
    } else {
      setIncrementUnit("hours");
      if (selectedModel === "graphcastgfs") {
        setIncrement(6);
        // Optionally adjust the default date to match one of the valid times (00Z, 06Z, 12Z, 18Z)
        let defaultDate = new Date(); // Or use modelRunDate if applicable
        const utcHour = defaultDate.getUTCHours();
        const roundedHour = utcHour - (utcHour % 6); // Rounds down to the nearest multiple of 6
        defaultDate.setUTCHours(roundedHour, 0, 0, 0); // Reset minutes, seconds, and milliseconds
        setSelectedDate(defaultDate);
        setValue("start_date", defaultDate);
      }
    }
  }, [selectedModel, modelRun, setValue]);
  

  const handleNumberChange = (event) => {
    const newValue = event.target.value; // Get the new input as string
    setIncrement(newValue === "" ? "" : Number(newValue)); // Store the raw input; convert to Number if not empty
  };

  const handleBlur = () => {
    if (selectedModel === "graphcastgfs") {
      // Convert the current increment value to an integer
      const currentValue = parseInt(increment, 10);
      // Round to the nearest multiple of 6
      const roundedValue = Math.round(currentValue / 6) * 6;
      // Update the state with the rounded value
      setIncrement(roundedValue);
    }
    // Ensure that increment has a valid number upon exiting the field (e.g., on blur)
    if (increment === "" || isNaN(increment)) {
      setIncrement(1); // Set to default value if current value is invalid
    }
  };

  const handleOnSubmit = () => {
    setSubmitted(true);
  };

  const handleNextIncrement = () => {
    let newDate = new Date(selectedDate);
    let actualIncrement = increment;

    if (incrementUnit === "hours") {
      let futureForecastHour = Number(forecastHour) + actualIncrement;
      let divisible = futureForecastHour % 3;
      // if we are abouve 120 hours and our incrementor is going to take us to a number not divisible by 3

      if (futureForecastHour > 120 && divisible !== 0) {
        // Calculate the nearest future forecast hour that is greater than current and divisible by 3
        const roundedUpToNearestThree = Math.ceil(futureForecastHour / 3) * 3;
        // console.log(roundedUpToNearestThree);
        const adjustedFutureForecastHour =
          roundedUpToNearestThree >= futureForecastHour
            ? roundedUpToNearestThree
            : roundedUpToNearestThree + 3;
        // console.log(adjustedFutureForecastHour);

        // Calculate the actual increment needed to reach this new forecast hour
        actualIncrement = adjustedFutureForecastHour - Number(forecastHour);

        // console.log('actual increment', actualIncrement);
        // console.log('adjustedFutureForecastHour', adjustedFutureForecastHour);

        // Update the increment state
        setIncrement(actualIncrement);

        // Calculate new date and hour
        let totalHoursToAdd = newDate.getUTCHours() + actualIncrement;
        newDate.setUTCHours(totalHoursToAdd);
      } else {
        // Simply add the increment to the current UTC hour
        newDate.setUTCHours(newDate.getUTCHours() + actualIncrement);
      }
    } else if (incrementUnit === "days") {
      // Calculate the future forecast hour
      let futureForecastHour = Number(forecastHour) + increment * 24;

      // Check if it's divisible by 3
      let divisible = futureForecastHour % 3;

      // If the future forecast hour is greater than 120 and not divisible by 3
      if (futureForecastHour > 120 && divisible !== 0) {
        // Add the days to the date
        newDate.setDate(newDate.getDate() + increment);

        // Get the current hour in UTC
        let newHour = newDate.getUTCHours();

        // Make sure newHour is divisible by 3
        newHour = Math.round(newHour / 3) * 3;

        // Set the new hour in UTC
        newDate.setUTCHours(newHour);
      } else {
        // Simply add the days to the date
        newDate.setDate(newDate.getDate() + increment);
      }

      // newDate.setDate(newDate.getDate() + increment);
    } else if (incrementUnit === "months") {
      newDate.setMonth(newDate.getMonth() + increment);
    } else if (incrementUnit === "years") {
      newDate.setFullYear(newDate.getFullYear() + increment);
    }

    setSelectedDate(newDate);
    setValue("start_date", newDate);
  };

  const handlePreviousIncrement = () => {
    let newDate = new Date(selectedDate);
    let actualIncrement = increment;
    let futureForecastHour = Number(forecastHour) - actualIncrement;

    if (incrementUnit === "hours") {
      if (futureForecastHour > 120) {
        // Find the closest multiple of 3 to the current increment
        actualIncrement = Math.round(increment / 3) * 3 || 3;
        // console.log('actualIncrement',actualIncrement)
        setIncrement(actualIncrement); // Update the increment state
      }
      newDate.setHours(newDate.getHours() - actualIncrement);
    } else if (incrementUnit === "days") {
      newDate.setDate(newDate.getDate() - increment);
    } else if (incrementUnit === "months") {
      newDate.setMonth(newDate.getMonth() - increment);
    } else if (incrementUnit === "years") {
      newDate.setFullYear(newDate.getFullYear() - increment);
    }
    setSelectedDate(newDate);
    setValue("start_date", newDate);
  };

  return (
    <div style={{ marginTop: "0px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group
          className="mb-3"
          controlId="model"
          {...register("basic_model")}
        >
          <Form.Label style={{ fontSize: "11px", marginBottom: "0px" }}>
            Model:
          </Form.Label>
          <ModelDropDown
            prefix="basic_"
            setSelectedModel={setSelectedModel}
            selectedModel={selectedModel}
            modelRun={modelRun}
            register={register}
          />
          {errors.model && <p className="errorMsg">{errors.model.message}</p>}
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="parameter"
          {...register("basic_parameter")}
        >
          <Form.Label style={{ fontSize: "11px", marginBottom: "0px" }}>
            Parameter:
          </Form.Label>
          <ParameterDropDown
            prefix="basic_"
            selectedModel={selectedModel}
            register={register}
            setValue={setValue}
            activeTabKey={activeTabKey}
            parameter={parameter}
            setParameter={setParameter}
          />

          {errors.parameter && (
            <p className="errorMsg">{errors.parameter.message}</p>
          )}
        </Form.Group>
        <DatePickerComponent
          control={control}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setValue={setValue}
          modelRunDate={modelRunDate}
          name={"start_date"}
          modelRun={modelRun}
          selectedModel={selectedModel}
          isLocalTime={isLocalTime}
          setIsLocalTime={setIsLocalTime}
          minDate={modelRunDate}
          maxDate={addHours(new Date(), allModels[modelRun])}
          errors={errors}
        />
        <DatePickerControlsComponent
          increment={increment}
          setIncrement={setIncrement}
          incrementUnit={incrementUnit}
          setIncrementUnit={setIncrementUnit}
          selectedModel={selectedModel}
          loading={loading}
          handleOnSubmit={handleOnSubmit}
          handleNextIncrement={handleNextIncrement}
          handlePreviousIncrement={handlePreviousIncrement}
          handleNumberChange={handleNumberChange}
          handleBlur={handleBlur}
          name={"Increment:"}
        />
      </form>
      <div className={styles.ramps_stats_container}>
        <DataStats stats={tiffStats} />
        <ModelStatistics
          modelRun={modelRun}
          setModelRun={setModelRun}
          allModels={allModels}
          forecastHour={forecastHour}
        />
        <div className={styles.sideform_color_ramp}>
          <ColorRamp
            setRampType={setRampType}
            setRampScale={setRampScale}
            rampType={rampType}
            rampScale={rampScale}
            setColorBlind={setColorBlind}
            parameter={parameter}
            name={"ramp_instant"}
          />
          <OpacitySlider opacity={opacity} setOpacity={setOpacity} />
        </div>
      </div>
    </div>
  );
};

export default InstantTab;
