import { React, useEffect, useState } from "react";
import SetColorScale from "../SetColorScale";
import { getWeatherParameterSettings } from "../SetColorScale";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import "./Legend.css";

const PrecipTypeLabels = () => {
  const labelStyle = {
    position: 'absolute',
    top: 'calc(50% - 10px)',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    color: 'black',
    fontWeight: 'bold',
    textShadow: '1px 1px 2px white, -1px -1px 2px white, 1px -1px 2px white, -1px 1px 2px white',
  };

  // Adjust these values as needed for precise positioning
  const rainStyle = { ...labelStyle, left: '9%' };
  const frzRainStyle = { ...labelStyle, left: '27%' };
  const SleetStyle = { ...labelStyle, left: '50%' };
  const snowStyle = { ...labelStyle, right: '25%' };
  const mixStyle = { ...labelStyle, right: '5%' };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <span style={rainStyle}>Rain</span>
      <span style={frzRainStyle}>FrzRain</span>
      <span style={SleetStyle}>Sleet</span>
      <span style={snowStyle}>Snow</span>
      <span style={mixStyle}>Mix</span>
    </div>
  );
};

const Gradient = ({ colors }) => {
  const stops = colors
    .map((color, index) => `${color} ${(index / (colors.length - 1)) * 100}%`)
    .join(", ");

  const style = {
    backgroundImage: `linear-gradient(to right, ${stops})`,
    height: "20px",
    width: "100%",
    borderRadius: "4px",
  };
  return <div style={style} />;
};

const LegendContinuous = ({
  min,
  max,
  setminval,
  setmaxval,
  rampScale,
  isLocked,
  setIsLocked,
  colorBlind,
  parameter
}) => {
  const [inputMin, setInputMin] = useState(min.toFixed(1)); // Store as string rounded to 1 decimal
  const [inputMax, setInputMax] = useState(max.toFixed(1)); // Store as string rounded to 1 decimal
  const [prevInputMin, setPrevInputMin] = useState(min.toFixed(1)); // Store as string rounded to 1 decimal
  const [prevInputMax, setPrevInputMax] = useState(max.toFixed(1)); // Store as string rounded to 1 decimal

  // onBlur for the Lower input
  const handleLowerBlur = (e) => {
    const valueStr = e.target.value;
    const value = parseFloat(valueStr);

    if (isNaN(value) || value >= inputMax) {
      // If input is not a number or greater than the max, revert to previous
      setInputMin(prevInputMin);
    } else {
      setminval(value);
      setInputMin(value.toFixed(1)); // Convert back to string rounded to 1 decimal
      setPrevInputMin(value.toFixed(1));
    }
  };

  // onBlur for the Upper input
  const handleUpperBlur = (e) => {
    const valueStr = e.target.value;
    const value = parseFloat(valueStr);

    if (isNaN(value) || value <= inputMin) {
      // If input is not a number or less than or equal to the min, revert to previous
      setInputMax(prevInputMax);
    } else {
      setmaxval(value);
      setInputMax(value.toFixed(1)); // Convert back to string rounded to 1 decimal
      setPrevInputMax(value.toFixed(1));
    }
  };

  const handleChangeMin = (e) => {
    const value = e.target.value;
    // Regular expression to allow only numbers and decimal point
    if (/^-?\d*\.?\d*$/.test(value)) {
      setInputMin(value); // Update state only if input is a number
    }
  };

  const handleChangeMax = (e) => {
    const value = e.target.value;
    // Regular expression to allow only numbers and decimal point
    if (/^-?\d*\.?\d*$/.test(value)) {
      setInputMax(value); // Update state only if input is a number
    }
  };

  const range = max - min;
  const colorScale = SetColorScale(
    min,
    max,
    parameter,
    "continuous",
    "",
    colorBlind
  );
  const colors = colorScale.colors(8).reverse();

  // Using the refactored function to get the settings
  const { decimal, unit } = getWeatherParameterSettings(
    parameter,
    range,
    "continuous"
  );

  const step = range / 10;

  const values = [];
  for (let i = min; i <= max; i += step) {
    values.push(i);
  }

  // const labels = values.map((value, index) => (
  //   <div key={index} style={{ flex: '1', textAlign: 'center' }}>
  //     <div>{value.toFixed(decimal)}{unit}</div>
  //   </div>
  // ));
  const labels = values.map((value, index) => (
    <div
      key={index}
      style={{
        flex: "1",
        textAlign: "center",
        textShadow:
          "0px 0px 3px white, 0px 0px 3px white, 0px 0px 3px white, 0px 0px 3px white", // White mask
        fontWeight: "bold",
        fontSize: "1.2em",
      }}
    >
      <div>
        {value.toFixed(decimal)}
        {unit}
      </div>
    </div>
  ));

  useEffect(() => {
    if (rampScale === "static") {
      setIsLocked(false);
    }
  }, [rampScale, setIsLocked]);

  useEffect(() => {
    if (rampScale === "static" || !isLocked) {
      setInputMin(min.toFixed(1));
      setInputMax(max.toFixed(1));
    }
  }, [rampScale, isLocked, min, max]);

  return (
    <div className="legend-container">
      {parameter === "combined" && <PrecipTypeLabels />}
      {rampScale === "relative" && (
        <div>
          <div className="min-position">
            <label className="dark-input">
              Lower:
              <input
                className="dark-input dynamic-width"
                type="text" // Changed to text
                // value={inputMin !== null ? inputMin.toFixed(decimal) : ""}
                value={inputMin}
                onChange={handleChangeMin}
                onBlur={handleLowerBlur}
                readOnly={isLocked}
              />
            </label>
          </div>
          <div className="max-position">
            <label className="dark-input">
              Upper:
              <input
                className="dark-input dynamic-width"
                type="text" // Changed to text
                value={inputMax}
                onChange={handleChangeMax}
                onBlur={handleUpperBlur}
                readOnly={isLocked}
              />
            </label>
          </div>
        </div>
      )}
      <Gradient colors={colors} />
      <div style={{ display: "flex" }}>{labels}</div>
      {rampScale === "relative" && ( // Conditionally render the button only when rampType is "relative"
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              {isLocked ? "Unlock color scale" : "Lock color scale"}
            </Tooltip>
          }
        >
          <Button
            className="lock-button"
            onClick={() => setIsLocked(!isLocked)}
          >
            <img
              src={isLocked ? "/assets/lock.png" : "/assets/unlock.png"}
              alt={isLocked ? "Locked" : "Unlocked"}
              style={{ width: "20px", height: "20px" }}
            />
          </Button>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default LegendContinuous;
