import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';

const ColorRamp = ({ setRampType, setRampScale, rampType, rampScale, setColorBlind, parameter, name }) => {
  
  const handleColorRampChange = (e) => {
    setRampScale(e.target.value);
  };

  const handleColorRamp2Change = (e) => {
    setRampType(e.target.value);
  };

  const handleColorBlindChange = (e) => {
    setColorBlind(e.target.checked);
  };

  // Check if the parameter is "combined"
  const isCombined = parameter === "combined";

  useEffect(() => {
      // Reset to default values for other parameters
      setRampScale('static'); // assuming 'relative' is the default
      setRampType('continuous'); // assuming 'continuous' is the default
  }, [isCombined, setRampScale, setRampType]);

  return (
    <div>
      <div>Color Ramp:</div>
      <Form.Group>
        <Form.Check
          inline
          type="radio"
          label="Static"
          name={name}
          id="static"
          value="static"
          checked={isCombined || rampScale === 'static'}
          onChange={handleColorRampChange}
        />
        <Form.Check
          inline
          type="radio"
          label="Relative"
          name={name}
          id="relative"
          value="relative"
          disabled={isCombined}
          checked={!isCombined && rampScale === 'relative'}
          onChange={handleColorRampChange}
        />
      </Form.Group>
      <Form.Group style={{"marginTop": "-7px"}}>
        <Form.Check
          inline
          type="radio"
          label="Continuous"
          name={`${name}_2`}
          id="continuous"
          value="continuous"
          checked={rampType === 'continuous'}
          onChange={handleColorRamp2Change}
        />
        <Form.Check
          inline
          type="radio"
          label="Discrete"
          name={`${name}_2`}
          id="discrete"
          value="discrete"
          checked={rampType === 'discrete'}
          onChange={handleColorRamp2Change}
        />
      </Form.Group>
      {/* <Form.Group>
        <Form.Check 
          style={{"marginTop": "0px"}}
          inline
          type="checkbox" 
          label="Color Blind" 
          onChange={handleColorBlindChange} 
        />
      </Form.Group> */}
    </div>
  );
};

export default ColorRamp;
