// DatePickerControlsComponent.jsx
import React from "react";
import { Form, Button } from "react-bootstrap";
import styles from "../SideForm.module.css";

const DatePickerControlsComponent = ({
  increment,
  incrementUnit,
  setIncrementUnit,
  selectedModel,
  loading,
  handleNextIncrement,
  handlePreviousIncrement,
  handleNumberChange,
  handleOnSubmit,
  handleBlur,
  name,
}) => {
  return (
    <Form.Group>
      <div className="submit-data" style={{ marginTop: "6px" }}>
        <Button
          type="submit"
          variant="primary"
          disabled={loading}
          onClick={handleOnSubmit}
          className={styles.submit_button}
        >
          Submit
        </Button>
      </div>
      <div className="datepicker-controls" style={{ marginTop: "6px" }}>
        {name !== "Step:" && (
          <>
            <Button
              style={{ marginRight: "10px" }}
              type="submit"
              variant="primary"
              onClick={handlePreviousIncrement}
              disabled={loading}
              className={styles.small_button}
            >
              {"<"}
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={handleNextIncrement}
              disabled={loading}
              className={styles.small_button}
            >
              {">"}
            </Button>
          </>
        )}
        <div style={{ marginTop: "6px" }}>
          <Form.Label>{name}</Form.Label>
          <input
            name="increment"
            max={384}
            min={1}
            type="number"
            style={{
              width: "41px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
            pattern="[0-9]*"
            value={increment}
            onChange={handleNumberChange}
            onBlur={handleBlur}
          />
          <select
            value={incrementUnit}
            onChange={(e) => setIncrementUnit(e.target.value)}
          >
            {selectedModel === "era5_monthly" ? (
              <>
                <option value="months">Months</option>
                <option value="years">Years</option>
              </>
            ) : selectedModel === "gfs_daily" ||
              selectedModel === "reanalysis_24" ? (
              <>
                <option value="days">Days</option>
              </>
            ) : selectedModel === "reanalysis_season" ? (
              <>
                <option value="years">Years</option>
              </>
            ) : selectedModel === "era5_daily" && name !== "Step:" ? (
              <>
                <option value="months">Months</option>
                <option value="days">Days</option>
              </>
            ) : selectedModel === "era5_daily" && name === "Step:" ? (
              <>
                <option value="days">Days</option>
              </>
            ) : name === "Step:" ? (
              <>
                <option value="hours">Hours</option>
              </>
            ) : (
              <>
                <option value="hours">Hours</option>
                <option value="days">Days</option>
              </>
            )}
          </select>
        </div>
      </div>
    </Form.Group>
  );
};

export default DatePickerControlsComponent;
