import React from "react";
import Typography from "@mui/material/Typography";
import styles from "./Map.module.css";

export default function Title() {
  return (
    <div className={styles.title}>
      <nav className="nav">
        <div className={styles.titlecontainer}>
          <img
            className={styles.titleimage}
            // src="./assets/Title_pic3.png"
            src="./assets/forecast/MoonCloudy.svg"
            // src="./assets/forecast/Sunny.png"
            alt="moon"
          />
          <Typography
  variant="subtitle1" // or another smaller variant
  component="h1"
  className={styles.sourceSerifPro}
          >
            <span style={{ color: "#CCCCCC" }}>P</span>
            <span style={{ color: "#CCCCCC" }}>T</span>
            <span style={{ color: "#B8B8B8" }}>W</span>
            <span style={{ color: "#B8B8B8" }}>e</span>
            <span style={{ color: "#A4A4A4" }}>a</span>
            <span style={{ color: "#A4A4A4" }}>t</span>
            <span style={{ color: "#909090" }}>h</span>
            <span style={{ color: "#909090" }}>e</span>
            <span style={{ color: "#7C7C7C" }}>r</span>
          </Typography>
        </div>
      </nav>
    </div>
  );
}
//cloud scale
{
  /* <Typography variant="h6" component="h1" className={styles.sourceSerifPro}>
<span style={{ color: '#CCCCCC' }}>P</span>
<span style={{ color: '#CCCCCC' }}>T</span>
<span style={{ color: '#B8B8B8' }}>W</span>
<span style={{ color: '#B8B8B8' }}>e</span>
<span style={{ color: '#A4A4A4' }}>a</span>
<span style={{ color: '#A4A4A4' }}>t</span>
<span style={{ color: '#909090' }}>h</span>
<span style={{ color: '#909090' }}>e</span>
<span style={{ color: '#7C7C7C' }}>r</span>
</Typography> */
}

//pressure ramp
{
  /* <Typography
variant="h6"
component="h1"
className={styles.sourceSerifPro}
>
<span style={{ color: "#B43CB7" }}>P</span>
<span style={{ color: "#C476D9" }}>T</span>
<span style={{ color: "#BF6EB7" }}>W</span>
<span style={{ color: "#C06091" }}>e</span>
<span style={{ color: "#D56D87" }}>a</span>
<span style={{ color: "#D16565" }}>t</span>
<span style={{ color: "#D15D5D" }}>h</span>
<span style={{ color: "#D15555" }}>e</span>
<span style={{ color: "#D14D4D" }}>r</span>
</Typography> */
}

//snow ramp
{
  /* <Typography
variant="h6"
component="h1"
className={styles.sourceSerifPro}
>
<span style={{ color: "#0000FF" }}>P</span>
<span style={{ color: "#1C00E6" }}>T</span>
<span style={{ color: "#3800CC" }}>W</span>
<span style={{ color: "#5400B2" }}>e</span>
<span style={{ color: "#700099" }}>a</span>
<span style={{ color: "#8C007F" }}>t</span>
<span style={{ color: "#A80066" }}>h</span>
<span style={{ color: "#99005C" }}>e</span>
<span style={{ color: "#890052" }}>r</span>
</Typography> */
}

//snow alternative
{
  /* <span style={{ color: '#1A1AFF' }}>P</span>
<span style={{ color: '#2A29FF' }}>T</span>
<span style={{ color: '#3A37FF' }}>W</span>
<span style={{ color: '#4A46FF' }}>e</span>
<span style={{ color: '#5A54FF' }}>a</span>
<span style={{ color: '#6B42E6' }}>t</span>
<span style={{ color: '#7C31CC' }}>h</span>
<span style={{ color: '#8D20B2' }}>e</span>
<span style={{ color: '#6633CC' }}>r</span> */
}

//snow alt2

{
  /* <span style={{ color: '#2A29FF' }}>P</span>
<span style={{ color: '#2A29FF' }}>T</span>
<span style={{ color: '#2A29FF' }}>W</span>
<span style={{ color: '#4A46FF' }}>e</span>
<span style={{ color: '#8D20B2' }}>a</span>
<span style={{ color: '#8D20B2' }}>t</span>
<span style={{ color: '#7C31CC' }}>h</span>
<span style={{ color: '#7C31CC' }}>e</span>
<span style={{ color: '#7C31CC' }}>r</span>  */
}
