import React, { useState, useEffect } from 'react';
import {createRoot} from 'react-dom/client';
import "./ForecastStyles.css"
import ForecastWindow from './ForecastDataDisplay';

export default function ShowForecast() {

  const [showForecast, setShowForecast] = useState(false);

  function showForecastHandler() {
    setShowForecast(true);
  }

  function hideForecastHandler() {
    setShowForecast(false);
  }
  

  useEffect(() => {
    if (showForecast) {
      const forecastContainer = document.createElement('div');
      const root = createRoot(forecastContainer);
      forecastContainer.className = 'forecast-container';
      document.body.appendChild(forecastContainer);

      root.render(<ForecastWindow onClose={hideForecastHandler} />);
    }
    else {
      const forecastContainer = document.querySelector('.forecast-container');
      if (forecastContainer) {
        forecastContainer.remove();
      }
    }
  }, [showForecast]);

  return (
    <div>
    <button className="forecastbutton" onClick={showForecastHandler}>Show Forecast</button>
    </div>
  );
}
// const forecastContainer = document.createElement('div');
// const root = createRoot(forecastContainer);
// root.render(
//   <div>
//     <ShowForecast latlng={[51.505, -0.09]} />
//   </div>
// );

