// ChangeView.js
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

// This component will receive the map object from MapContainer and perform the zoom.
function ChangeView({ coords }) {
    const map = useMap();
    useEffect(() => {
        if (coords) {
            map.flyTo([coords.latitude, coords.longitude], 13);
        }
    }, [coords, map]);

    return null;
}

export default ChangeView;