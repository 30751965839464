import { useEffect, useState, useRef } from "react";
import { useMap } from "react-leaflet";
import { distance } from "@turf/turf";
import L from "leaflet";
import styles from "./MeasureStyles.module.css";

function MeasureDistanceTool({ setIsActive, isActive, onToggle }) {
  const map = useMap();
  const [points, setPoints] = useState([]);
  const [isMeasuring, setIsMeasuring] = useState(false);

  const currentLineRef = useRef(null);

  useEffect(() => {
    if (isActive !== isMeasuring) {
      setIsMeasuring(isActive);
    }
  }, [isActive]);

  useEffect(() => {
    if (!isMeasuring) {
      // If isMeasuring is currently false, remove the current line from the map
      if (currentLineRef.current) {
        currentLineRef.current.remove();
        currentLineRef.current = null;
        setIsMeasuring(isActive);
      }
      // Reset the points state to an empty array
      setPoints([]);
    }
  }, [isMeasuring]);

  useEffect(() => {
    const measureControl = L.control({ position: "topleft" });

    measureControl.onAdd = function () {
      const div = L.DomUtil.create("div", "my-linemeasure-control");

      div.innerHTML = `<img 
                src="./assets/measure_dist.svg"
                class="${styles.measure_dist_button_common} ${
        isMeasuring
          ? styles.measure_dist_button_dark
          : styles.measure_dist_button
      }" 
                alt="Measure"
                title="Measure mode distance">`;

      div.onclick = (e) => {
        setIsActive(false);
        L.DomEvent.stopPropagation(e);
        L.DomEvent.preventDefault(e);
        onToggle(); // Call the onToggle handler passed down from the parent component
      };

      return div;
    };

    measureControl.addTo(map);

    return () => {
      measureControl.remove();
    };
  }, [map, onToggle, setIsActive, isMeasuring]);

  useEffect(() => {
    const onMapClick = (e) => {
      if (!isMeasuring) return;
      // Measuring.Provider = true;

      setPoints((prevPoints) => {
        const newPoints = [...prevPoints, e.latlng];

        if (newPoints.length === 2) {
          // Remove the previous line from the map
          if (currentLineRef.current) {
            currentLineRef.current.remove();
            currentLineRef.current = null;
            // Measuring.Provider = false;
          }

          const from = [newPoints[0].lat, newPoints[0].lng];
          const to = [e.latlng.lat, e.latlng.lng];
          const options = { units: "miles" };
          const dist = distance(from, to, options);

          currentLineRef.current = L.polyline([from, to], {
            color: "red",
            weight: 3,
            opacity: 1,
          }).addTo(map);
          // Adding a tooltip with the distance
          currentLineRef.current
            .bindTooltip(`${dist.toFixed(2)} mi`, {
              className: styles.darkTooltip,
            })
            .openTooltip();

          return [];
        }

        return newPoints;
      });
    };
    const onMapMouseMove = (e) => {
      if (points.length === 1) {
        const from = [points[0].lat, points[0].lng];
        const to = [e.latlng.lat, e.latlng.lng];

        // Create or update the polyline to follow the cursor
        if (currentLineRef.current) {
          currentLineRef.current.setLatLngs([from, to]);
        } else {
          currentLineRef.current = L.polyline([from, to], {
            color: "red",
            weight: 5,
            opacity: 1,
          }).addTo(map);
        }
      }
    };

    if (isMeasuring) {
      map.on("click", onMapClick);
      map.on("mousemove", onMapMouseMove);
    }

    return () => {
      map.off("click", onMapClick);
      map.off("mousemove", onMapMouseMove);
    };
  }, [map, isMeasuring, points]);

  useEffect(() => {
    if (!isMeasuring) {
      setIsActive(true);
    }
  }, [isMeasuring, setIsActive]);

  return null;
}

export default MeasureDistanceTool;
