import React, { useState, useEffect } from 'react';
import "./OpacitySlider.css";

const OpacitySlider = ({ opacity, setOpacity }) => {
  const [tempOpacity, setTempOpacity] = useState(opacity);

  // Update local state if the external opacity prop changes
  useEffect(() => {
    setTempOpacity(opacity);
  }, [opacity]);

  const handleOpacityChange = (e) => {
    const newOpacity = e.target.valueAsNumber;
    setTempOpacity(newOpacity);
  };

  const finalizeOpacityChange = (e) => {
    const newOpacity = e.target.valueAsNumber;
    setOpacity(newOpacity);
  };

  return (
    <div className="opacity-slider-container">
      <label className="opacity-label">Opacity</label>
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={tempOpacity}
        onInput={handleOpacityChange}
        onMouseUp={finalizeOpacityChange}
        onBlur={finalizeOpacityChange}
        onTouchEnd={finalizeOpacityChange}  // Handle touch end event for mobile devices
      />
    </div>
  );
};

export default OpacitySlider;
