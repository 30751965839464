// DatePickerComponent.jsx
import React from "react";
import DatePicker from "react-datepicker";

import { Form, Button } from "react-bootstrap";
import { differenceInHours } from "date-fns";
import { Controller } from "react-hook-form";

import "react-datepicker/dist/react-datepicker.css";
import styles from "../SideForm.module.css";

const DatePickerComponent = ({
  control,
  selectedDate,
  setSelectedDate,
  setValue,
  modelRun,
  name,
  selectedModel,
  isLocalTime,
  setIsLocalTime,
  minDate,
  maxDate,
  errors,
}) => {
  const isGraphcastValidTimeSelection = (time) => {
    const utcHour = time.getUTCHours(); // Get UTC hour from selected time
    return [0, 6, 12, 18].includes(utcHour); // Check if it matches 00Z, 06Z, 12Z, or 18Z
  };

  const extractDateFromModelRun = (modelRun) => {
    const dateMatch = modelRun.match(/(\d{4}-\d{2}-\d{2})/);
    const timeMatch = modelRun.match(/\((\d{2})Z\)/);

    if (dateMatch && timeMatch) {
      const [year, month, day] = dateMatch[1].split("-").map(Number);
      const hour = Number(timeMatch[1]);

      // Create a new Date object in UTC
      return new Date(Date.UTC(year, month - 1, day, hour));
    }

    return null;
  };

  const modelRunDate = extractDateFromModelRun(modelRun);
  const calculateTimeInterval = () => {
    if (modelRunDate) {
      const hoursSinceModelRun = differenceInHours(new Date(), modelRunDate);
      return hoursSinceModelRun > 120 ? 180 : 60; // 180 minutes (3 hours) if more than 120 hours since model run, else 60 minutes
    }
    return 60; // Default to 60 minutes if no modelRunDate
  };

  const timeInterval = calculateTimeInterval();
  // Function to check if a given time is a valid selection
  const isValidTimeSelection = (time) => {
    const hoursSinceModelRun = differenceInHours(time, modelRunDate);

    return hoursSinceModelRun <= 120 || time.getUTCHours() % 3 === 0;
  };

  return (
    <Form.Group className="mb-3" controlId={name}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {name === "start_date" ? (
          <Form.Label
            style={{ fontSize: "11px", marginBottom: "0px", marginTop: "0px" }}
          >
            <Button
              className={styles.utc_toggle}
              style={{
                fontSize: "11px",
                marginBottom: "0px",
                marginTop: "0px",
              }}
              onClick={() => {
                if (
                  ![
                    "era5_monthly",
                    "era5_daily",
                    "gfs_daily",
                    "reanalysis_season",
                    "reanalysis_24",
                  ].includes(selectedModel)
                ) {
                  setIsLocalTime(!isLocalTime);
                }
              }}
              disabled={[
                "era5_monthly",
                "era5_daily",
                "gfs_daily",
                "reanalysis_season",
                "reanalysis_24",
              ].includes(selectedModel)}
            >
              {isLocalTime ? "Start Date: (Local)" : "Start Date: (UTC)"}
            </Button>
          </Form.Label>
        ) : (
          <Form.Label
            style={{
              fontSize: "11px",
              marginBottom: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            End Date:
          </Form.Label>
        )}
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <DatePicker
            ref={ref}
            className={
              ["era5_monthly", "reanalysis_season"].includes(selectedModel)
                ? "smaller-date-box"
                : ["era5_daily", "gfs_daily", "reanalysis_24"].includes(
                    selectedModel
                  )
                ? "medium-date-box"
                : "regular-date-box"
            }
            onChange={(date) => {
              setSelectedDate(date);
              onChange(date);
              setValue(name, date);
            }}
            onBlur={onBlur}
            selected={
              isLocalTime
                ? selectedDate
                : new Date(
                    selectedDate.getTime() +
                      selectedDate.getTimezoneOffset() * 60000
                  )
            }
            showTimeSelect={
              !"era5_monthly era5_daily gfs_daily reanalysis_season reanalysis_24"
                .split(" ")
                .includes(selectedModel)
            }
            minDate={minDate}
            maxDate={maxDate}
            filterTime={(time) => {
              if (selectedModel === "graphcastgfs") {
                return isGraphcastValidTimeSelection(time);
              } else {
                return isValidTimeSelection(time);
              }
            }}
            timeFormat="HH:mm"
            timeIntervals={timeInterval}
            timeCaption="time"
            dateFormat={
              selectedModel === "era5_monthly"
                ? "MM/yyyy"
                : selectedModel === "reanalysis_season"
                ? "yyyy"
                : ["era5_daily", "gfs_daily", "reanalysis_24"].includes(
                    selectedModel
                  )
                ? "MM/d/yyyy"
                : "MM/d/yyyy h:mm aa"
            }
            showMonthYearPicker={selectedModel === "era5_monthly"}
          />
        )}
      />
      {errors.datepicker && (
        <p className="errorMsg">{errors.datepicker.message}</p>
      )}
    </Form.Group>
  );
};

export default DatePickerComponent;
