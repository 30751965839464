// import { calculateNewValue } from '@testing-library/user-event/dist/utils';
import React, { useState, useRef, createContext, useEffect } from "react";
import { FeatureGroup, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useCoordinates } from "./CoordinatesContext";
import ChangeView from "./ChangeView";
import LocationButton from "./LocationButton"; // Adjust the path based on your project structure
import { useMap } from "react-leaflet";
import L from "leaflet";
import { act } from "react-dom/test-utils";

const UserPoly = createContext();

const LeafletDrawShapes = ({
  setWeatherFile,
  isActive,
  isMeasuringArea,
  isMeasuringDistance,
  submitted,
  setSubmitted,
  activeTabKey,
}) => {
  const [coordinates, setCoordinates] = useCoordinates();
  const [mapLayers, setMapLayers] = useState([]);
  const [newPolygonDrawn, setNewPolygonDrawn] = useState(false);

  const lastPolygonIdRef = useRef(null);
  const activeTabKeyRef = useRef(activeTabKey);
  activeTabKeyRef.current = activeTabKey;

  UserPoly.Provider = null;
  const map = useMap();
  // used for the inital polygon when first coming to PTWeather
  const defaultPolygonCoords = [
    { lng: -151.56, lat: 9.86 },
    { lng: -151.56, lat: 58.69 },
    { lng: -44.35, lat: 58.69 },
    { lng: -44.35, lat: 9.86 },
    { lng: -151.56, lat: 9.86 },
  ];

  // Define a simple circular icon using CSS
  const greenDotIcon = L.divIcon({
    className: "green-dot-icon", // Custom class for styling
    html: '<div style="background-color: green; width: 20px; height: 20px; border-radius: 50%; position: relative; left: -10px; top: -10px;"></div>', // Inner HTML to create a green dot
    iconSize: [20, 20], // Size of the dot
    iconAnchor: [10, 10], // Center the dot correctly over the coordinate
  });


  function differentiateCoordinates(jsonString) {
    try {
        const data = JSON.parse(jsonString);

        if (Array.isArray(data)) {
            // It's an array, check if every element has lat and lng properties
            const allHaveLatAndLng = data.every(item => item.hasOwnProperty('lat') && item.hasOwnProperty('lng'));
            if (allHaveLatAndLng) {
                return "polygon";
            } else {
                console.log("Array format not correct for coordinates.");
                return "error";
            }
        } else if (typeof data === 'object' && data.hasOwnProperty('lat') && data.hasOwnProperty('lng')) {
            // It's a single object with lat and lng properties
            return "point";
        } else {
            console.log("Object format not correct for a single point.");
            return "error";
        }
    } catch (error) {
        console.log("Error parsing JSON:", error);
        return "error";
    }
}

  useEffect(() => {
    setCoordinates(JSON.stringify(defaultPolygonCoords));
  }, []);

  const onCreated = (e) => {
    const { layer } = e;
    const { _leaflet_id } = layer;

    // Check the type of the layer created
    if (e.layerType === "marker" && activeTabKeyRef.current === "graph") {
      // Remove the previous marker if it exists
      if (lastPolygonIdRef.current && map._layers[lastPolygonIdRef.current]) {
        map.removeLayer(map._layers[lastPolygonIdRef.current]);
      }
    } else {
      // Remove previous polygon from map
      if (lastPolygonIdRef.current) {
        e.sourceTarget._layers[lastPolygonIdRef.current].remove();
      }
    }

    // Store the new layer id
    lastPolygonIdRef.current = _leaflet_id;

    // Update the coordinates state based on layer type
    if (e.layerType === "polygon" || e.layerType === "rectangle") {
      setCoordinates(JSON.stringify(layer._latlngs[0]));
      setNewPolygonDrawn(true);
    } else if (e.layerType === "marker") {
      setCoordinates(JSON.stringify(layer.getLatLng()));
    }

  };

  const onEdited = (e, geom) => {
    const {
      layers: { _layers },
    } = e;

    geom = JSON.stringify(Object.values(_layers)[0]._latlngs[0]);

    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id
            ? { ...l, latlngs: { ...editing.latlngs[0] } }
            : l
        )
      );
      return setCoordinates(geom);
    });
    // console.log(JSON.stringify(mapLayers))
  };

  const onDeleted = (e) => {
    // if (!isActive) return;
    //nulling variable as feature no longer exists now
    lastPolygonIdRef.current = null;
    setWeatherFile(null);
    // console.log('Deleted data', e);
  };

  // When the submit button in the side menu is clicked this zooms to the drawn box
  useEffect(() => {
    
    if (coordinates) {
      let parsedCoordinates
      let type = differentiateCoordinates(coordinates)
      
      setSubmitted(false);
      // Assuming coordinates is a JSON string of LatLng objects
      if (type === "polygon") {
      parsedCoordinates = JSON.parse(coordinates).map((coord) => [
        coord.lat,
        coord.lng,
      ]);
      if (parsedCoordinates.length > 0) {
        // Create a Leaflet bounds object from the coordinates
        const bounds = L.latLngBounds(parsedCoordinates);
        map.fitBounds(bounds); // Zoom the map to the bounds
      }
      }else{
        let coordinatesObj = JSON.parse(coordinates);
        parsedCoordinates = [coordinatesObj.lat, coordinatesObj.lng];
      }

    }
  }, [submitted]);

  const [coords, setCoords] = useState(null);

  return (
    <FeatureGroup>
      <LocationButton onLocationFound={(e) => setCoords(e.latlng)} />
      {coords && <ChangeView coords={coords} />}
      <EditControl
        key={`${isActive ? "enabled" : "disabled"}-${
          isMeasuringArea ? "measuringArea" : "notMeasuringArea"
        }-${
          isMeasuringDistance ? "measuringDistance" : "notMeasuringDistance"
        }`}
        position="topleft"
        onCreated={onCreated}
        onEdited={onEdited}
        onDeleted={onDeleted}
        draw={{
          rectangle:
            isActive && !isMeasuringArea && !isMeasuringDistance
              ? {
                  allowIntersection: false,
                  showArea: false,
                  shapeOptions: {
                    color: "blue",
                    opacity: 0.6,
                    fillOpacity: 0,
                  },
                }
              : false,
          circle: false,
          polyline: false,
          circlemarker: false,
          marker:
            activeTabKey === "graph" // Only enables marker drawing when activeTabKey is "graph"
              ? {
                  icon: greenDotIcon, // Use the custom green dot icon
                }
              : false, // Disables marker drawing when activeTabKey is not "graph"
          edit: { remove: true },
          polygon:
            isActive && !isMeasuringArea && !isMeasuringDistance && activeTabKey !== "graph"
              ? {
                  allowIntersection: false,
                  showArea: false,
                  shapeOptions: {
                    color: "blue",
                    opacity: 0.6,
                    fillOpacity: 0,
                  },
                }
              : false,
        }}
      />

      {!newPolygonDrawn && (
        <Polygon
          positions={defaultPolygonCoords.map((coord) => [
            coord.lat,
            coord.lng,
          ])}
          color="blue"
          fillOpacity={0}
        />
      )}
    </FeatureGroup>
  );
};

export default LeafletDrawShapes;
export { UserPoly };
