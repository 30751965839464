import L from 'leaflet';
import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import styles from './Map.module.css';

const LocationButton = ({ onLocationFound }) => {
  const map = useMap();
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    map.locate();
    map.on('locationfound', (e) => {
      setUserLocation(e.latlng);
    });
  }, [map]);

  useEffect(() => {
    if (userLocation && !map.zoomToLocationControl) {
      L.Control.Watermark = L.Control.extend({
        onAdd: function () {
          var img = L.DomUtil.create('img');
          img.src = './assets/zoom-to-location.svg';
          img.style.width = '33px';
          img.style.border = '1px solid grey';
          img.title = 'zoom to location';
          img.className = styles.zoom_to_location_button; // Apply the CSS module class
          

          img.onclick = function () {
            if (userLocation) {
              const blueDotIcon = L.divIcon({
                className: 'blue-dot-icon',
                html: '<div style="background-color: rgba(0, 0, 255, 0.65); width: 12px; height: 12px; border-radius: 50%;"></div>',
                iconSize: [12, 12],
                iconAnchor: [6, 6],
              });

              L.marker(userLocation, { icon: blueDotIcon }).addTo(map);
              map.flyTo(userLocation, 12);
              onLocationFound(userLocation);
            }
          };
          return img;
        },
        onRemove: function () {
          // Nothing to do here
        },
      });

      L.control.watermark = function (opts) {
        return new L.Control.Watermark(opts);
      };

      map.zoomToLocationControl = L.control.watermark({ position: 'topleft' }).addTo(map);
    }
  }, [map, userLocation, onLocationFound]);

  return null;
};

export default LocationButton;
