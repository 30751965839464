import { useEffect, useState, useRef } from "react";
import { useMap } from "react-leaflet";
import { area } from "@turf/turf";
import L from "leaflet";
import styles from "./MeasureStyles.module.css";
import Measuring from "./MeasuringContext";

function MeasureAreaTool({ setIsActive, isActive, onToggle }) {
  const map = useMap();
  const [points, setPoints] = useState([]);
  const [isMeasuring, setIsMeasuring] = useState(false);

  const currentPolygonRef = useRef(null);

  useEffect(() => {
    if (isActive !== isMeasuring) {
      setIsMeasuring(isActive);
    }
  }, [isActive]);

  useEffect(() => {
    if (!isMeasuring) {
      // Measuring.Provider = false;
      if (currentPolygonRef.current) {
        currentPolygonRef.current.remove();
        currentPolygonRef.current = null;
        setIsMeasuring(isActive);
      }
      setPoints([]);
    }
  }, [isMeasuring]);

  useEffect(() => {
    const measureControl = L.control({ position: "topleft" });

    measureControl.onAdd = function () {
      const div = L.DomUtil.create("div", "my-areameasure-control");

      div.innerHTML = `<img 
            src="./assets/measure_area.svg"
            class="${styles.measure_area_button_common} ${
        isMeasuring
          ? styles.measure_area_button_dark
          : styles.measure_area_button
      }"
            alt="Measure"
            Title="Measure mode area">`;

      div.onclick = (e) => {
        setIsActive(false); // Set the external isActive state to false
        L.DomEvent.stopPropagation(e);
        L.DomEvent.preventDefault(e);
        onToggle(); // Call the onToggle handler passed down from the parent component
      };

      return div;
    };

    measureControl.addTo(map);

    return () => {
      measureControl.remove();
    };
  }, [map, onToggle, setIsActive, isMeasuring]);

  useEffect(() => {
    const onMapClick = (e) => {
      if (!isMeasuring) return;
      // Measuring.Provider = true;

      setPoints((prevPoints) => [...prevPoints, e.latlng]);
    };

    const onMapDoubleClick = (e) => {
      if (!isMeasuring || points.length < 3) return;

      L.DomEvent.stopPropagation(e); // Prevent the map from moving on double click

      const latlngs = points.map((point) => [point.lat, point.lng]);
      const polygon = {
        type: "Polygon",
        coordinates: [latlngs.concat([latlngs[0]])], // Close the polygon
      };
      const polygonArea = area(polygon);

      if (currentPolygonRef.current) {
        currentPolygonRef.current.remove();
        currentPolygonRef.current = null;
      }

      currentPolygonRef.current = L.polygon(latlngs, {
        color: "red",
        weight: 3,
        opacity: 1,
      }).addTo(map);
      //   currentPolygonRef.current.bindTooltip(`${(polygonArea / 1000000).toFixed(2)} km²`).openTooltip();

      currentPolygonRef.current
        .bindTooltip(`${(polygonArea * 3.861e-7).toFixed(2)} mi²`, {
          className: styles.darkTooltip,
        })
        .openTooltip();

      setPoints([]);
    };

    const onMapMouseMove = (e) => {
      if (points.length >= 1) {
        const latlngs = points
          .map((point) => [point.lat, point.lng])
          .concat([[e.latlng.lat, e.latlng.lng]]);

        if (currentPolygonRef.current) {
          currentPolygonRef.current.setLatLngs(latlngs);
        } else {
          currentPolygonRef.current = L.polygon(latlngs, {
            color: "red",
            weight: 5,
            opacity: 1,
          }).addTo(map);
        }
      }
    };

    const onMapContextMenu = (e) => {
      if (!isMeasuring) return;

      // Create a custom context menu
      const contextMenu = L.popup({ closeButton: false })
        .setLatLng(e.latlng)
        .setContent(
          '<a href="#" onclick="window.finishPolygonMeasureAreaTool()">Finish</a>'
        )
        .openOn(map);

      // Define a global function to finalize the polygon when "Finish" is clicked
      window.finishPolygonMeasureAreaTool = () => {
        onMapDoubleClick(e);
        map.closePopup();
      };
    };

    if (isMeasuring) {
      map.on("click", onMapClick);
      map.on("dblclick", onMapDoubleClick);
      map.on("mousemove", onMapMouseMove);
      map.on("contextmenu", onMapContextMenu);
    }

    return () => {
      map.off("click", onMapClick);
      map.off("dblclick", onMapDoubleClick);
      map.off("mousemove", onMapMouseMove);
      map.off("contextmenu", onMapContextMenu);
      delete window.finishPolygonMeasureAreaTool;
      //   setIsActive(true)
    };
  }, [map, isMeasuring, points]);

  useEffect(() => {
    if (isMeasuring) {
      map.doubleClickZoom.disable(); // Disable double click zoom when measuring
    } else {
      map.doubleClickZoom.enable(); // Enable double click zoom when not measuring
    }

    return () => {
      map.doubleClickZoom.enable(); // Ensure double click zoom is enabled when component is unmounted
    };
  }, [isMeasuring, map]);

  useEffect(() => {
    if (!isMeasuring) {
      setIsActive(true);
    }
  }, [isMeasuring, setIsActive]);

  return null;
}

export default MeasureAreaTool;
