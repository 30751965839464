import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { ForecastCoords } from "../LoadRaster";

function GenerateForecastData(selectedDate, setSelectedDate, selectedForecastModel, setSelectedForecastModel, setForecastModelRun) {

  const [currentDate, setCurrentDate] = useState(selectedDate);

  const [alldata, setAlldata] = useState([]);
  
  const lon = Number(ForecastCoords.Provider[0]);
  const lat = Number(ForecastCoords.Provider[1]);

  // Function to request forecast for a specific day
  const getForecastForDate = useCallback(
    (date) => {
      let API_KEY = process.env.REACT_APP_API_KEY;
      // gathering time up to the day and the offset in time zones between users local time and utc time.
      const timezoneOffsetHours = new Date().getTimezoneOffset() / 60;
      const formattedDateDay = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      axios
        .post(`${backendUrl}/api/forecast`, {
          location: [lon, lat],
          model: selectedForecastModel,
          day: formattedDateDay,
          model_res: API_KEY,
          offset: timezoneOffsetHours
        })
        .then((response) => {
          setAlldata(response.data.weather_data);
          setForecastModelRun(response.data.model_run_date);
          // console.log(response.data.weather_data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [lon, lat, selectedForecastModel]
  );

  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);

  const maxDate = new Date(new Date().setHours(new Date().getHours() + 384));
  maxDate.setHours(0, 0, 0, 0);

  const handleNextDay = () => {
    const nextDay = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    nextDay.setHours(0, 0, 0, 0);
    if (nextDay <= maxDate) {
      setCurrentDate(nextDay);
      setSelectedDate(nextDay);
    }
  };

  const handlePreviousDay = () => {
    const previousDay = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
    previousDay.setHours(0, 0, 0, 0);
    if (previousDay >= minDate) {
      setCurrentDate(previousDay);
      setSelectedDate(previousDay);
    }
  };

  // Logic for mapping the data and returning the necessary weather data goes here
  const weatherdata = alldata.map((data) => {

    // Get timezone offset in minutes
    const now = new Date();
    const timezoneOffsetMinutes = now.getTimezoneOffset();

    // Convert date and time to local
    const dateObj = new Date(data.forecast_time);
    const localDateObj = new Date(
      dateObj.getTime() - timezoneOffsetMinutes * 60 * 1000
    );

    const localTime = localDateObj.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    
    // Remove the minutes and the space, and make "AM"/"PM" lowercase
    const formattedTime = localTime.replace(/:00 /, "").toLowerCase();
    
    // Remove the leading zero from the hour, if any
    const simplifiedTime = formattedTime.replace(/^0/, "");
    
  // Use the condition directly from data
    const condition = data.condition;
    // console.log(simplifiedTime, localTime)

    // const hour = localDateObj.getHours();
    // const isNight = hour >= 19 || hour < 7;

    // Determine the condition based on cloud_cover
    // let condition;

    // if (data.precipitation > 0 && data.precipitation < 0.2) {
    //   condition = "Rainy";
    // } else if (data.precipitation >= 0.2) {
    //   condition = "Stormy";
    // } else if (data.cloudcover < 10) {
    //   if (isNight) {
    //     condition = "Moony";
    //   } else {
    //     if (data.temperature < 90) {
    //       condition = "Sunny";
    //     } else {
    //       condition = "SunnyHot";
    //     }
    //   }
    // } else if (data.cloudcover >= 10 && data.cloudcover <= 50) {
    //   if (isNight) {
    //     condition = "MoonCloudy";
    //   } else {
    //     condition = "PartlyCloudy";
    //   }
    // } else {
    //   condition = "Cloudy";
    // }

    return {
      time: simplifiedTime,
      condition: condition,
      // icon: conditionIcons[condition],
      temp: data.temperature,
      precip: data.precipitation,
      wind: data.wind,
      gusts: data.windgusts,
      clouds: data.cloudcover,
      
    };
  });

  useEffect(() => {
    getForecastForDate(currentDate);
  }, [currentDate, getForecastForDate]);

  //Update currentDate when selectedDate changes
  useEffect(() => {
    setCurrentDate(selectedDate);
  }, [selectedDate]);

  return { weatherdata, handleNextDay, handlePreviousDay};
}

export default GenerateForecastData;
