import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './GlobalStyles.css';
import WeatherMap from './components/WeatherMap';
import About from './components/About/About';
import Terms from './components/About/TermsOfUse/TermsOfUse';
// import MaintenancePage from './components/Maintenance/MaintenancePage';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          {/* <Route path="/" element={<MaintenancePage />} /> */}
          <Route path="/" element={<WeatherMap />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms-of-use" element={<Terms />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
