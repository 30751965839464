import React from 'react';


// Scrollable container that is being filled with the forecast and maybe future other things.
const ScrollableContainer = ({ children }) => {
  return (
    <div>
      {children}
    </div>
  );
};

export default ScrollableContainer;