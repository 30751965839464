import chroma from "chroma-js";

const staticValues = {
  cloudcover: [0, 20, 40, 60, 80, 100],
  combined: [1.0,1.2,1.4,1.6,1.8,2.0,2.2,2.4,2.6,2.8,3.0,3.2,3.4,3.6,3.8,4.0,4.2,4.4,4.6,4.8,5.0,5.2,5.4,5.6,5.8,6.0],
  frzcat: [0, 20, 40, 60, 80, 100],
  frzrain: [0, 0.1, 0.2, 0.3, 0.4, 0.5],
  hail: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
  kucheraratio: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20],
  lightning: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20],
  precipitation: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
  preciprate: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
  // pressure: [940, 950, 960, 970, 980, 990, 1000, 1010, 1020, 1030, 1040],
  pressure: [27.8, 28.1, 28.4, 28.7, 29.0, 29.3, 29.6, 29.9, 30.2, 30.5, 30.8],
  radar: [0, 10, 20, 30, 40, 50, 60, 70],
  seaice: [0, 33, 66, 100],
  seaiceanom: [-70, -60, -50, -40, -30, -20, -10, 0, 10, 20, 30, 40, 50, 60, 70],
  sleet: [0, 0.2, 0.4, 0.6, 0.8, 1.0],
  raincat: [0, 20, 40, 60, 80, 100],
  snow: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  snowkuchera: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  snowcat: [0, 20, 40, 60, 80, 100],
  snowdepth: [0, 10, 20, 30, 40, 50],
  sst: [35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90],
  sstanom: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5],
  temperature: [-14, -2, 8, 20, 32, 44, 56, 68, 80, 92, 104, 116],
  temperaturemin: [-14, -2, 8, 20, 32, 44, 56, 68, 80, 92, 104, 116],
  temperaturemax: [-14, -2, 8, 20, 32, 44, 56, 68, 80, 92, 104, 116],
  temperatureanom: [-21, -18, -15, -12, -9, -6, -3, 0, 3, 6, 9, 12, 15, 18, 21],
  wind: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
  wind300: [60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180],
  windgusts: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
  visibility: [0, 20, 40, 60, 80, 100]
};

export const comRainScale = ['#b2fab4', '#8ae68a', '#62d162', '#3abc3a', '#138014'];
export const comFreezingRainScale = ['#dcb5f2', '#ca8de6', '#b764da', '#a43bcd', '#7200a8'];
export const comSleetScale = ['#ffecb3', '#ffb74d', '#ff9800', '#fb8c00', '#f57c00'];
export const comSnowScale = ['#b3e6ff', '#66ccff', '#3399ff', '#0066ff', '#003366'];
export const comMixScale = ['#d9d9d9', '#bfbfbf', '#a6a6a6', '#8c8c8c', '#737373'];

// valueType: continuous or discrete, rampType: static or relative
function SetColorScale(min, max, parameter, valueType, rampType, colorBlind) {
  
  // console.log('setcolorscale', min, max, weatherParam, valueType, rampType, colorBlind)
  let scales;
  // Define individual scales

  // Combine scales to create the 'combined' scale
  const combinedScale = [
    ...comRainScale,
    ...comFreezingRainScale,
    ...comSleetScale,
    ...comSnowScale,
    ...comMixScale
  ];

  if (!colorBlind) {

    // Define discrete scales and values
    scales = {
      // cloudcover: ['#404040', '#5C5C5C', '#797979', '#969696', '#B3B3B3', '#d9d9d9'],
      // Adding transparancy to less cloud cover for spiffyness
      cloudcover: [
        'rgba(110, 110, 110, .6)', 
        'rgba(130, 130, 130, .7)',
        'rgba(150, 150, 150, .8)',
        'rgba(170, 170, 170, .85)',
        'rgba(190, 190, 190, .9)',
        'rgba(210, 210, 210, .95)',
        'rgba(230, 230, 230, 1)'
      ],
      frzrain: ['#FCDEF9', '#FCA7F3', '#FC71EE', '#FC3BE9', '#FC05E4'],
      hail: ['blue', 'purple', 'pink'], // 3 colors
      // combined: ['red','blue','green','purple'],
      combined: combinedScale,
      kucheraratio: ['blue', 'purple', 'pink'], //3 clors
      lightning: ['#0000FF', '#00BFFF', '#00FF80', '#088A08', '#FFFF00', '#D7DF01', '#FFBF00', '#FF8000', '#FF4000', '#FF0000', '#DF01D7', '#FE2EF7'], // 12 colors
      precipitation: ['#00FF80', '#00FF00', '#31B404', '#088A08', '#FFFF00', '#D7DF01', '#FFBF00', '#FF8000', '#FF4000', '#FF0000'], // 10 colors
      preciprate: ['#00FF80', '#00FF00', '#31B404', '#088A08', '#FFFF00', '#D7DF01', '#FFBF00', '#FF8000', '#FF4000', '#FF0000'], // 10 colors
      pressure: ['#f74a05', '#ed6618', '#e37d30', '#e3963d', '#edc89d', '#e7bceb', '#e68cd7', '#e354cb', '#a438cf', '#5f0d80'], // 10 colors 
      radar: ['#0000FF', '#00BFFF', '#00FF80', '#088A08', '#FFFF00', '#D7DF01', '#FFBF00', '#FF8000', '#FF4000', '#FF0000', '#DF01D7', '#FE2EF7'], // 12 colors
      seaice: ['#20ebfa', '#659dfc', '#1302fa'], // 3 colors
      seaiceanom: ['#000080', '#0000CD', '#4169E1', '#1E90FF', '#87CEEB', '#B0E0E6', 'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)', '#FFA07A', '#FA8072', '#F08080', '#CD5C5C', '#DC143C', '#8B0000'], // 14 colors
      raincat:['#ccf2ff', '#006080'],
      sleet: ['#FAEDD4', '#F3CCA1', '#ECAC6E', '#E58C3B', '#DE6C09'],
      snow: ['#696969', '#66ccff', '#0000FF', '#00008B', '#D8BFD8', '#800080', '#4B0082', '#FFB6C1', '#FF69B4', '#C71585'], //10 colors
      snowkuchera: ['#696969', '#ADD8E6', '#0000FF', '#00008B', '#D8BFD8', '#800080', '#4B0082', '#FFB6C1', '#FF69B4', '#C71585'], //10 colors
      snowdepth: ['#696969', '#ADD8E6', '#0000FF', '#00008B', '#D8BFD8', '#800080', '#4B0082', '#FFB6C1', '#FF69B4', '#C71585'], //10 colors
      sst: ['#DF01D7', '#FE2EF7', '#0000FF', '#00BFFF', '#04B404', '#58FA58', '#FFFF00', '#FFBF00', '#FF8000', '#FF0000', '#F6CECE'], // 11 colors
      sstanom: ['#800080', '#0000FF', '#1E90FF', '#00BFFF', '#B0E0E6', '#C0C0C0', '#C0C0C0', '#FFD700', '#FFA500', '#FF4500', '#FF0000', '#B22222', '#8B0000'], // 10colors
      temperature: ['#DF01D7', '#FE2EF7', '#0000FF', '#00BFFF', '#04B404', '#58FA58', '#FFFF00', '#FFBF00', '#FF8000', '#FF0000', '#F6CECE'], // 11 colors
      temperaturemin: ['#DF01D7', '#FE2EF7', '#0000FF', '#00BFFF', '#04B404', '#58FA58', '#FFFF00', '#FFBF00', '#FF8000', '#FF0000', '#F6CECE'], // 11 colors
      temperaturemax: ['#DF01D7', '#FE2EF7', '#0000FF', '#00BFFF', '#04B404', '#58FA58', '#FFFF00', '#FFBF00', '#FF8000', '#FF0000', '#F6CECE'], // 11 colors
      temperatureanom: ['#000080', '#0000CD', '#4169E1', '#1E90FF', '#87CEEB', '#B0E0E6', 'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)', '#FFA07A', '#FA8072', '#F08080', '#CD5C5C', '#DC143C', '#8B0000'], // 14 colors
      wind: ['#e0f5d7', '#87ed5f', '#03ab17', '#1f0cf0', '#ed87e5', '#da0afa', '#faf20a', '#e6994c', '#f55b02', '#e64e4e', '#f00202', '#f7c6c6'], // 12 colors
      wind300: ['#e0f5d700', '#87ed5f', '#03ab17', '#1f0cf0', '#ed87e5', '#da0afa', '#faf20a', '#e6994c', '#f55b02', '#e64e4e', '#f00202', '#f7c6c6'], // 12 colors
      windgusts: ['#e0f5d7', '#87ed5f', '#03ab17', '#1f0cf0', '#ed87e5', '#da0afa', '#faf20a', '#e6994c', '#f55b02', '#e64e4e', '#f00202', '#f7c6c6'] // 12 colors
    };
  }
  else {

    // color blind scales
    scales = {
      // cloudcover: ['#404040', '#5C5C5C', '#797979', '#969696', '#B3B3B3', '#d9d9d9'],
      cloudcover: [
        'rgba(110, 110, 110, .6)', 
        'rgba(130, 130, 130, .7)',
        'rgba(150, 150, 150, .8)',
        'rgba(170, 170, 170, .85)',
        'rgba(190, 190, 190, .9)',
        'rgba(210, 210, 210, .95)',
        'rgba(230, 230, 230, 1)'
      ],
      frzrain: ['#FCDEF9', '#FCA7F3', '#FC71EE', '#FC3BE9', '#FC05E4'],
      hail: ['#ccffcc', '#99ff99', '#66ff66'], // 3 colors
      combined: combinedScale,
      kucheraratio: ['#ffccff', '#ff99ff', '#ff66ff'], // 3 colors
      lightning: ['#ffcccc', '#ff9999', '#ff6666', '#ff3333', '#ff0000', '#cc0000', '#990000', '#660000', '#330000', '#000000', '#333333', '#666666'], // 12 colors
      precipitation: ['#ccf2ff', '#99e6ff', '#66d9ff', '#33ccff', '#00bfff', '#00ace6', '#0099cc', '#0086b3', '#007399', '#006080'], // 10 colors
      preciprate: ['#ccf2ff', '#99e6ff', '#66d9ff', '#33ccff', '#00bfff', '#00ace6', '#0099cc', '#0086b3', '#007399', '#006080'], // 10 colors
      pressure: ['#f2ccff', '#e6b3ff', '#d9a6ff', '#cc99ff', '#bf80ff', '#ac66ff', '#994dff', '#8640ff', '#7326ff', '#6013ff'], // 10 colors 
      radar: ['#ffcc99', '#ff9966', '#ff6633', '#ff3300', '#ff0000', '#cc0000', '#990000', '#660000', '#330000', '#000000', '#333333', '#666666'], // 12 colors
      raincat:['#ccf2ff', '#006080'],
      seaice: ['#20ebfa', '#659dfc', '#1302fa'], // 3 colors
      seaiceanom: ['#000080', '#0000CD', '#4169E1', '#1E90FF', '#87CEEB', '#B0E0E6', 'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)', '#FFA07A', '#FA8072', '#F08080', '#CD5C5C', '#DC143C', '#8B0000'], // 14 colors
      sleet: ['#FAEDD4', '#F3CCA1', '#ECAC6E', '#E58C3B', '#DE6C09'],
      snow: ['#e6e6e6', '#cccccc', '#b3b3b3', '#999999', '#808080', '#666666', '#4d4d4d', '#333333', '#191919', '#000000'], // 10 colors
      snowkuchera: ['#e6e6e6', '#cccccc', '#b3b3b3', '#999999', '#808080', '#666666', '#4d4d4d', '#333333', '#191919', '#000000'], // 10 colors
      snowdepth: ['#e6e6e6', '#cccccc', '#b3b3b3', '#999999', '#808080', '#666666', '#4d4d4d', '#333333', '#191919', '#000000'], // 10 colors
      sst: ['#000033', '#000000', '#330000', '#660000', '#990000', '#cc0000', '#ff0000', '#ff3333', '#ff6666', '#ff9999', '#ffcccc'], // 11 colors
      sstanom: ['#800080', '#0000FF', '#1E90FF', '#00BFFF', '#B0E0E6', '#C0C0C0', '#C0C0C0', '#FFD700', '#FFA500', '#FF4500', '#FF0000', '#B22222', '#8B0000'], // 10colors
      temperature: ['#000033', '#000000', '#330000', '#660000', '#990000', '#cc0000', '#ff0000', '#ff3333', '#ff6666', '#ff9999', '#ffcccc'], // 11 colors
      temperaturemin: ['#000033', '#000000', '#330000', '#660000', '#990000', '#cc0000', '#ff0000', '#ff3333', '#ff6666', '#ff9999', '#ffcccc'], // 11 colors
      temperaturemax: ['#000033', '#000000', '#330000', '#660000', '#990000', '#cc0000', '#ff0000', '#ff3333', '#ff6666', '#ff9999', '#ffcccc'], // 11 colors
      temperatureanom: ['#00008B', '#0000FF', '#87CEEB', '#B0E0E6', '#FA8072', '#FFA07A', '#FF0000', '#8B0000'], // 8 colors
      wind: ['#ccccff', '#9999ff', '#6666ff', '#3333ff', '#0000ff', '#0000cc', '#000099', '#000066', '#000033', '#000000', '#333333', '#666666'], // 12 colors
      wind300: ['#ccccff00', '#9999ff', '#6666ff', '#3333ff', '#0000ff', '#0000cc', '#000099', '#000066', '#000033', '#000000', '#333333', '#666666'], // 12 colors
      windgusts: ['#ccccff', '#9999ff', '#6666ff', '#3333ff', '#0000ff', '#0000cc', '#000099', '#000066', '#000033', '#000000', '#333333', '#666666'] // 12 colors
    };


  }


  // Makes the steps used the same as the equivilent parameter in the scales dictionary above -1.
  const discreteSteps = Object.keys(scales).reduce((acc, key) => {
    acc[key] = scales[key].length - 1;
    return acc;
  }, {});

  if (valueType === "continuous") {
    const colorArray = scales[parameter];
    if (colorArray) {
      const reversedColors = colorArray.reverse(); // Create a copy and reverse it
      const colorScale = chroma.scale(reversedColors).domain([1, 0]);
      return colorScale;
    } else {
      console.log("Error with weatherParam when creating continuous raster");
    }
  }

  if (valueType === "discrete") {
    let values;
    if (rampType === "static") {
      values = staticValues[parameter];
    } else {
      const step = discreteSteps[parameter];
      const decimal = 1//discreteDecimals[weatherParam];
      const relative_step = (max - min) / (step + 1);
      const length = (max - min) / relative_step + 1;
      values = Array.from({ length }, (_, i) =>
        (min + i * relative_step).toFixed(decimal)
      );
    }

    const colorScale = chroma.scale(scales[parameter]).classes(values);
    return { colorScale, values };
  }


}

export default SetColorScale;

export function getWeatherParameterSettings(parameter, range, legendType) {
  // Define common settings for each weather parameter
  const commonSettings = {
    cloudcover: { decimal: 0, unit: "%" },
    combined: { decimal: 1, unit: "" },
    frzcat: { decimal: 0, unit: "%" },
    frzrain: { decimal: 2, unit: '"' },
    hail: { decimal: 2, unit: '"' },
    kucheraratio: { decimal: 0, unit: ":" },
    lighning: { decimal: 0, unit: "%" },
    precipitation: { decimal: 2, unit: '"' },
    preciprate: { decimal: 2, unit: '"/hr' },
    pressure: { decimal: 1, unit: '"hg' },
    radar: { decimal: 0, unit: "%" },
    seaice: { decimal: 0, unit: "%" },
    seaiceanom: { decimal: 0, unit: "%" },
    sleet: { decimal: 2, unit: '"' },
    raincat: { decimal: 0, unit: "%" },
    snow: { decimal: 2, unit: '"' },
    snowkuchera: { decimal: 2, unit: '"' },
    snowcat: { decimal: 0, unit: "%" },
    snowdepth: { decimal: 2, unit: '"' },
    sst: { decimal: 0, unit: "°F" },
    sstanom: { decimal: 0, unit: "°F" },
    temperature: { decimal: 0, unit: "°F" },
    temperaturemin: { decimal: 0, unit: "°F" },
    temperaturemax: { decimal: 0, unit: "°F" },
    temperatureanom: { decimal: 0, unit: "°F" },
    wind: { decimal: 0, unit: "mph" },
    wind300: { decimal: 0, unit: "mph" },
    windgusts: { decimal: 0, unit: "mph" },
    visibility: { decimal: 0, unit: "%" },
  };

  const settings = commonSettings[parameter];

  // Return the combined settings
  return { ...settings };
}

//Uses the global staticValues variable to automatically set the minimum and maximum value for a range.
export function getStaticWeatherRange(parameter) {
  const weatherRanges = Object.keys(staticValues).reduce((acc, key) => {
    acc[key] = { min: Math.min(...staticValues[key]), max: Math.max(...staticValues[key]) };
    return acc;
  }, {});

  const { min = 0, max = 0 } = weatherRanges[parameter] || {};

  return { min, max };
}
