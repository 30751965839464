import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useForm } from "react-hook-form";
import { format, addHours, set } from "date-fns";
import { React, createContext, useState, useEffect } from "react";
import { useCoordinates } from "../CoordinatesContext";
import InstantTab from "./SideMenuTabs/InstantTab";
import RangeTab from "./SideMenuTabs/RangeTab";
import GraphTab from "./SideMenuTabs/GraphTab";
import styles from "./SideForm.module.css";

function calculateBoxArea(coords) {
  let area = 0;

  if (coords.length > 2) {
    for (let i = 0; i < coords.length - 1; i++) {
      area += coords[i][0] * coords[i + 1][1] - coords[i + 1][0] * coords[i][1];
    }
    area = Math.abs(area / 2);
  }

  return area;
}

const MyTiff = createContext(null);

export default function SideMenu({
  setWeatherFile,
  setRampType,
  setRampScale,
  rampType,
  rampScale,
  setColorBlind,
  parameter,
  setParameter,
  opacity,
  setOpacity,
  selectedModel,
  setSelectedModel,
  setSubmitted,
  activeTabKey,
  setActiveTabKey,
  displayPopupImage,
}) {
  const now = new Date();
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  const [coordinates, setCoordinates] = useCoordinates();
  const [raster, setRaster] = useState([]);
  const [forecastHour, setForecastHour] = useState(0); // forecast hour
  const [modelRun, setModelRun] = useState("NA"); // model run date
  const [tiffStats, setTiffStats] = useState([0, 0, 0]); // min max mean
  const [loading, setLoading] = useState(false);
  const [allModels, setAllModels] = useState({});
  const [hasShownAlert, setHasShownAlert] = useState(false);
  const [hasMadeDefaultRequest, setHasMadeDefaultRequest] = useState(false);
  const [selectedDate, setSelectedDate] = useState(now);
  const [increment, setIncrement] = useState(1);
  const [incrementUnit, setIncrementUnit] = useState("hours");

  // For graphs

  const MAX_AGGS_BOX_AREA_QUART_DEG = 8000;
  const MAX_AGGS_BOX_AREA_3KM = 300;

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const tabTitleClass = (key) => {
    return key === activeTabKey
      ? styles.sideformTabSelected
      : styles.sideformTabUnselected;
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      model: "gfs",
      start_date: now,
    },
  });

  const handleSelect = (key) => {
    setActiveTabKey(key);
    setValue("tab", key);
    setValue(key + "_model", "gfs"); // Reset the model for the active tab
    setSelectedModel("gfs");
  };

  useEffect(() => {
    // console.log("selectedModel changed:", selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    if (selectedModel) {
      axios
        .get(`${backendUrl}/api/model-run?model=${selectedModel}`, {
          // // Include the API key in the request headers
          // headers: {
          //   'X-API-KEY': API_KEY
          // }
        })
        .then((response) => {
          // console.log("Response:", response);
          setModelRun(response.data.latestModelRun);
          setAllModels(response.data.modelRuns);
        })
        .catch((error) => {
          console.error("Failed to fetch latest model run:", error);
        });
    }
  }, [selectedModel, setModelRun]);

  //This happens only once (default map) when the user first comes to the page, once modelRun updates
  useEffect(() => {
    if (!hasMadeDefaultRequest && modelRun !== "NA") {
      // Check if modelRun is updated and not "NA"
      if (modelRun !== "NA") {
        // Get the timezone offset in minutes and convert it to hours
        const timezoneOffset = new Date().getTimezoneOffset() * -1;
        // Create a new date object that adjusts for the timezone offset
        const adjustedDate = new Date(
          selectedDate.getTime() - timezoneOffset * 60000
        );
        // Define the default weather request
        const defaultWeatherRequest = {
          location: [
            [
              [-151.56, 9.86],
              [-151.56, 58.69],
              [-44.35, 58.69],
              [-44.35, 9.86],
              [-151.56, 9.86],
            ],
          ],
          parameter: "combined", // Example parameter
          datetime: format(adjustedDate, "yyyy-MM-dd HH:00:00"),
          model: selectedModel,
          model_res: process.env.REACT_APP_API_KEY,
          forecast_hour: 0,
          modelRun: modelRun,
        };
        setParameter("combined");
        // Prepare the request options
        const options_download = {
          url: `${backendUrl}/api/basic`, // URL to your /api/basic endpoint
          method: "POST",
          responseType: "blob", // As per your requirement
          data: defaultWeatherRequest,
        };

        // Make the API request
        axios(options_download)
          .then(function (response) {
            setHasMadeDefaultRequest(true);
            // Handle the response, e.g., update the map with the received data
            const href = URL.createObjectURL(response.data);
            setWeatherFile(href); // Assuming setWeatherFile updates the map
            // setRaster(response.data);

            setForecastHour(response.headers["x-forecast-hour"]);

            // setTiffStats(response.headers["x-stats"]);
            let min = response.headers["x-stats-min"];
            let max = response.headers["x-stats-max"];
            let mean = response.headers["x-stats-mean"];

            setTiffStats([min, max, mean]);

            let warning = response.headers["x-warning"];
            if (warning && warning !== "False") {
              alert(`Warning: ${warning}`); // Display the warning in an alert
            }
          })
          .catch(function (error) {
            console.error("Error while fetching default weather data:", error);
          });
      }
    }
  }, [modelRun]);

  useEffect(() => {
    // console.log(activeTabKey)
    setValue("tab", activeTabKey);
    if (activeTabKey === "graph") {
      setIncrement(1);
    }
  }, [activeTabKey, setValue]);

  const onSubmit = (data) => {
    const coords = JSON.parse(coordinates);

    const formatted_coords = [];

    // for (var key in coords) {
    //   formatted_coords.push([
    //     Number(parseFloat(coords[key]["lng"]).toFixed(2)),
    //     Number(parseFloat(coords[key]["lat"]).toFixed(2)),
    //   ]);
    // }

    if (Array.isArray(coords)) {
      coords.forEach((coord) => {
        const formatted = [
          Number(parseFloat(coord.lng).toFixed(2)),
          Number(parseFloat(coord.lat).toFixed(2)),
        ];
        formatted_coords.push(formatted);
      });
      formatted_coords.push(formatted_coords[0]);
    } else {
      const formatted = [
        Number(parseFloat(coords.lng).toFixed(2)),
        Number(parseFloat(coords.lat).toFixed(2)),
      ];
      formatted_coords.push(formatted);
    }

    let model,
      param,
      weather_request,
      api_url,
      utc_date,
      utc_start_date,
      utc_end_date;

    let timezoneOffsethours = now.getTimezoneOffset() / 60;
    // date user enters in local time
    let local_date = new Date(data.start_date);
    // equivilent utc date in the weather model in the format the api needs

    // const end_date = (format(new Date(data.start_date), 'yyyy-MM-dd HH:00:00'))
    let API_KEY = process.env.REACT_APP_API_KEY;

    switch (data.tab) {
      // lets me handle each tab differently

      case "aggs":
      case "graph":
        // Validate the size of the drawn box
        const area = calculateBoxArea(formatted_coords); // Implement this function to calculate the area of the box
        // console.log('area',area)
        // console.log(area);
        // console.log(data.aggs_model);
        if (
          area > MAX_AGGS_BOX_AREA_3KM &&
          !hasShownAlert &&
          (data.aggs_model === "nam" || data.aggs_model === "hrrr")
        ) {
          setHasShownAlert(true);
          alert(
            "Drawn Weather Window is too large for 3 KM resolution aggregations. Please draw a smaller one."
          );
          return; // Prevent the form from submitting and the API call from being made
          break;
        } else if (area > MAX_AGGS_BOX_AREA_QUART_DEG && !hasShownAlert) {
          setHasShownAlert(true);
          alert(
            "Drawn Weather Window is too large for quarter degree resolution aggregations. Please draw a smaller one."
          );
          return; // Prevent the form from submitting and the API call from being made
          break;
        }

        setHasShownAlert(false);
        // console.log(data);
        const start_time = data.aggs_start_date;
        const end_time = data.aggs_end_date;

        if (data.tab === "aggs") {
          model = data.aggs_model;
          param = data.aggs_parameter;
        } else if (data.tab === "graph") {
          model = data.graph_model;
          param = data.graph_parameter;
        } else {
          // Fallback model or error handling can be added here
          model = data.aggs_model; // Default fallback to aggs_model, adjust as needed
        }

        if (model === "era5_monthly") {
          // Format the local_date a string in the format you desire
          utc_start_date = format(start_time, "yyyy-MM-01 00:00:00");
          utc_end_date = format(end_time, "yyyy-MM-01 00:00:00");
        } else if (model === "era5_daily") {
          // Format the local_date to a string in the format you desire
          utc_start_date = format(start_time, "yyyy-MM-dd 00:00:00");
          utc_end_date = format(end_time, "yyyy-MM-dd 00:00:00");
        } else if (model === "gfs_daily") {
          // Format the local_date to a string in the format you desire
          utc_start_date = format(start_time, "yyyy-MM-dd 00:00:00");
          utc_end_date = format(end_time, "yyyy-MM-dd 00:00:00");
        } else {
          utc_start_date = format(
            addHours(start_time, timezoneOffsethours),
            "yyyy-MM-dd HH:00:00"
          );
          utc_end_date = format(
            addHours(end_time, timezoneOffsethours),
            "yyyy-MM-dd HH:00:00"
          );
        }

        // console.log(utc_start_date, utc_end_date);
        // start_date = (format(new Date(data.start_date), 'yyyy-MM-dd HH:00:00'))

        weather_request = {
          location: [formatted_coords],
          parameter: param,
          start_time: utc_start_date,
          end_time: utc_end_date,
          model_res: API_KEY,
          modelRun: modelRun,
        };

        if (data.tab === "graph") {
          weather_request.step = increment; // Assume increment is fetched from the form data
          weather_request.stepUnit = incrementUnit; // Assume incrementUnit is fetched from the form data
          weather_request.model = data.graph_model;
        } else {
          weather_request.model = data.aggs_model;
        }
        // console.log(weather_request);
        // api_url = `${backendUrl}/api/aggs`;
        api_url = `${backendUrl}/api/${data.tab}`;
        break;
      case "basic":
        // For era5 data we are only looking at monthly averages or totals so we don't need to worry about timezones
        if (data.basic_model === "era5_monthly") {
          utc_date = format(local_date, "yyyy-MM-01 00:00:00");
        } else if (data.basic_model === "reanalysis_season") {
          if (parameter === "snow") {
            utc_date = format(local_date, "yyyy-09-30 12:00:00");
          } else {
            utc_date = format(local_date, "yyyy-01-01 00:00:00");
          }
        } else if (
          data.basic_model === "era5_daily" ||
          data.basic_model === "reanalysis_24"
        ) {
          utc_date = format(local_date, "yyyy-MM-dd 00:00:00");
          // } else if (data.basic_model === "gfs_daily") {
          //   utc_date = format(local_date, "yyyy-MM-dd HH:00:00");
        } else {
          // Format the local_date to a string in the format you desire
          utc_date = format(
            addHours(local_date, timezoneOffsethours),
            "yyyy-MM-dd HH:00:00"
          );
        }

        // const utc_date = format(
        //   addHours(local_date, timezoneOffsethours),
        //   "yyyy-MM-dd HH:00:00"
        // );

        param = data.basic_parameter;
        model = data.basic_model;
        // console.log(data)

        // start_date = (format(new Date(data.start_date), 'yyyy-MM-dd HH:00:00'))
        weather_request = {
          location: [formatted_coords],
          parameter: param,
          datetime: utc_date,
          model: model,
          model_res: API_KEY,
          forecast_hour: forecastHour,
          modelRun: modelRun,
        };

        api_url = `${backendUrl}/api/basic`;

        break;

      default:
        console.error(`Unexpected data.tab value: ${data.tab}`);
        break;
    }

    const options_download = {
      url: api_url, //your url
      method: "POST",
      responseType: "blob", // important
      data: weather_request,
    };

    setLoading(true);
    axios(options_download)
      .then(function (response) {
        setLoading(false);
        const contentType = response.headers["content-type"];

        setModelRun(response.headers["x-model-run"]);
        setForecastHour(response.headers["x-forecast-hour"]);

        if (contentType === "image/png") {
          const imageHref = URL.createObjectURL(response.data);
          let min = response.headers["x-stats-min"];
          let max = response.headers["x-stats-max"];
          let mean = response.headers["x-stats-mean"];
          setTiffStats([min, max, mean]);

          let warning = response.headers["x-warning"];
          if (warning && warning !== "False") {
            alert(`Warning: ${warning}`); // Display the warning in an alert
          }
          // Trigger the function to display the image in a popup
          displayPopupImage(imageHref);
        } else if (contentType === "image/tiff") {
          const href = URL.createObjectURL(response.data);

          let min = response.headers["x-stats-min"];
          let max = response.headers["x-stats-max"];
          let mean = response.headers["x-stats-mean"];
          setTiffStats([min, max, mean]);

          let warning = response.headers["x-warning"];
          if (warning && warning !== "False") {
            alert(`Warning: ${warning}`); // Display the warning in an alert
          }

          MyTiff.Provider = href;
          setWeatherFile(href);
          setRaster(response.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div className={styles.sideform_container}>
      <div
        className={`${
          activeTabKey === "basic"
            ? styles.sideform_basic
            : activeTabKey === "graph"
            ? styles.sideform_graph
            : styles.sideform_aggs
        }`}
      >
        <div className={styles.tabsContainer}>
          <Tabs defaultActiveKey="basic" id="menu-tabs" onSelect={handleSelect}>
            <Tab
              eventKey="basic"
              title={<span className={tabTitleClass("basic")}>Instant</span>}
            >
              <InstantTab
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                setValue={setValue}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                modelRun={modelRun}
                setModelRun={setModelRun}
                allModels={allModels}
                forecastHour={forecastHour}
                tiffStats={tiffStats}
                now={now}
                activeTabKey={activeTabKey}
                loading={loading}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setParameter={setParameter}
                setSubmitted={setSubmitted}
                parameter={parameter}
                setRampType={setRampType}
                setRampScale={setRampScale}
                rampType={rampType}
                rampScale={rampScale}
                setColorBlind={setColorBlind}
                opacity={opacity}
                setOpacity={setOpacity}
                increment={increment}
                setIncrement={setIncrement}
                incrementUnit={incrementUnit}
                setIncrementUnit={setIncrementUnit}
              />
            </Tab>
            <Tab
              eventKey="aggs"
              title={<span className={tabTitleClass("aggs")}>Range</span>}
            >
              <RangeTab
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                setValue={setValue}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                modelRun={modelRun}
                setModelRun={setModelRun}
                allModels={allModels}
                forecastHour={forecastHour}
                tiffStats={tiffStats}
                now={now}
                activeTabKey={activeTabKey}
                loading={loading}
                setParameter={setParameter}
                setSubmitted={setSubmitted}
                parameter={parameter}
                setRampType={setRampType}
                setRampScale={setRampScale}
                rampType={rampType}
                rampScale={rampScale}
                setColorBlind={setColorBlind}
                opacity={opacity}
                setOpacity={setOpacity}
                increment={increment}
                setIncrement={setIncrement}
                incrementUnit={incrementUnit}
                setIncrementUnit={setIncrementUnit}
              />
            </Tab>
            <Tab
              eventKey="graph"
              title={<span className={tabTitleClass("graph")}>Graph</span>}
            >
              <GraphTab
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                setValue={setValue}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                modelRun={modelRun}
                setModelRun={setModelRun}
                allModels={allModels}
                forecastHour={forecastHour}
                tiffStats={tiffStats}
                now={now}
                activeTabKey={activeTabKey}
                loading={loading}
                setParameter={setParameter}
                setSubmitted={setSubmitted}
                parameter={parameter}
                setRampType={setRampType}
                setRampScale={setRampScale}
                rampType={rampType}
                rampScale={rampScale}
                setColorBlind={setColorBlind}
                opacity={opacity}
                setOpacity={setOpacity}
                increment={increment}
                setIncrement={setIncrement}
                incrementUnit={incrementUnit}
                setIncrementUnit={setIncrementUnit}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
export { MyTiff };
