import { React, useEffect, useState } from "react";
import SetColorScale, { getWeatherParameterSettings } from "../SetColorScale";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";

const Swatch = ({ color }) => {
  const style = {
    backgroundColor: color,
    height: "20px",
    width: "100%",
  };
  return <div style={style} />;
};

const PrecipTypeLabels = () => {
  const labelStyle = {
    position: "absolute",
    top: "calc(50% - 10px)",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "black",
    fontWeight: "bold",
    textShadow:
      "1px 1px 2px white, -1px -1px 2px white, 1px -1px 2px white, -1px 1px 2px white",
  };

  // Adjust these values as needed for precise positioning
  const rainStyle = { ...labelStyle, left: "9%" };
  const frzRainStyle = { ...labelStyle, left: "27%" };
  const SleetStyle = { ...labelStyle, left: "50%" };
  const snowStyle = { ...labelStyle, right: "25%" };
  const mixStyle = { ...labelStyle, right: "5%" };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <span style={rainStyle}>Rain</span>
      <span style={frzRainStyle}>FrzRain</span>
      <span style={SleetStyle}>Sleet</span>
      <span style={snowStyle}>Snow</span>
      <span style={mixStyle}>Mix</span>
    </div>
  );
};

const LegendDiscrete = ({
  min,
  max,
  setminval,
  setmaxval,
  rampScale,
  isLocked,
  setIsLocked,
  colorBlind,
  parameter,
}) => {
  const [inputMin, setInputMin] = useState(min.toFixed(1)); // Store as string rounded to 1 decimal
  const [inputMax, setInputMax] = useState(max.toFixed(1)); // Store as string rounded to 1 decimal
  const [prevInputMin, setPrevInputMin] = useState(min.toFixed(1)); // Store as string rounded to 1 decimal
  const [prevInputMax, setPrevInputMax] = useState(max.toFixed(1)); // Store as string rounded to 1 decimal

  // onBlur for the Lower input
  const handleLowerBlur = (e) => {
    const valueStr = e.target.value;
    const value = parseFloat(valueStr);

    if (isNaN(value) || value >= inputMax) {
      // If input is not a number or greater than the max, revert to previous
      setInputMin(prevInputMin);
    } else {
      setminval(value);
      setInputMin(value.toFixed(1)); // Convert back to string rounded to 1 decimal
      setPrevInputMin(value.toFixed(1));
    }
  };

  // onBlur for the Upper input
  const handleUpperBlur = (e) => {
    const valueStr = e.target.value;
    const value = parseFloat(valueStr);

    if (isNaN(value) || value <= inputMin) {
      // If input is not a number or less than or equal to the min, revert to previous
      setInputMax(prevInputMax);
    } else {
      setmaxval(value);
      setInputMax(value.toFixed(1)); // Convert back to string rounded to 1 decimal
      setPrevInputMax(value.toFixed(1));
    }
  };

  const handleChangeMin = (e) => {
    const value = e.target.value;
    // Regular expression to allow only numbers and decimal point
    if (/^-?\d*\.?\d*$/.test(value)) {
      setInputMin(value); // Update state only if input is a number
    }
  };

  const handleChangeMax = (e) => {
    const value = e.target.value;
    // Regular expression to allow only numbers and decimal point
    if (/^-?\d*\.?\d*$/.test(value)) {
      setInputMax(value); // Update state only if input is a number
    }
  };

  let colorScaleInfo;
  useEffect(() => {
    if (rampScale === "static") {
      setIsLocked(false);
    }
  }, [rampScale, setIsLocked]);

  if (rampScale === "relative") {
    colorScaleInfo = SetColorScale(
      min,
      max,
      parameter,
      "discrete",
      "relative",
      colorBlind
    );
  } else if (rampScale === "static") {
    colorScaleInfo = SetColorScale(
      min,
      max,
      parameter,
      "discrete",
      "static",
      colorBlind
    );
  }

  const colors = colorScaleInfo.colorScale.colors();
  const values = colorScaleInfo.values;

  const range = max - min;

  // Using the refactored function to get the settings
  const { decimal, unit } = getWeatherParameterSettings(
    parameter,
    range,
    "discrete"
  );

  const labels = values.map((value, index) => {
    const color = colors[index];
    return (
      <div
        key={index}
        style={{
          flex: "1",
          textAlign: "left",
          textShadow:
            "0px 0px 3px white, 0px 0px 3px white, 0px 0px 3px white, 0px 0px 3px white", // White mask
          fontWeight: "bold",
          fontSize: "1.2em",
        }}
      >
        <Swatch color={color} />
        {index !== 0 && index !== values.length - 1 && (
          <div>
            {Number(value).toFixed(decimal)}
            {unit}
          </div>
        )}
      </div>
    );
  });

  useEffect(() => {
    if (rampScale === "static" || !isLocked) {
      setInputMin(min.toFixed(1));
      setInputMax(max.toFixed(1));
    }
  }, [rampScale, isLocked, min, max]);

  useEffect(() => {
    if (!isLocked) {
      setPrevInputMin(min);
      setPrevInputMax(max);
    }
  }, [min, max, isLocked]);

  return (
    <div className="legend-container">
      {parameter === "combined" && <PrecipTypeLabels />}
      {rampScale === "relative" && (
        <div>
          <div className="min-position">
            <label className="dark-input">
              Lower:
              <input
                className="dark-input dynamic-width"
                type="text" // Changed to text
                // value={inputMin !== null ? inputMin.toFixed(decimal) : ""}
                value={inputMin}
                onChange={handleChangeMin}
                onBlur={handleLowerBlur}
                readOnly={isLocked}
              />
            </label>
          </div>
          <div className="max-position">
            <label className="dark-input">
              Upper:
              <input
                className="dark-input dynamic-width"
                type="text" // Changed to text
                value={inputMax}
                onChange={handleChangeMax}
                onBlur={handleUpperBlur}
                readOnly={isLocked}
              />
            </label>
          </div>
        </div>
      )}
      <div style={{ display: "flex", alignItems: "left", width: "109%" }}>
        {labels}
      </div>
      <div
      // style={{
      //   zIndex: 9999,
      //   position: "absolute",
      //   backgroundColor: "white",
      //   top: 0,
      // }}
      >
        {rampScale === "relative" && ( // Conditionally render the button only when rampType is "relative"
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {isLocked ? "Unlock color scale" : "Lock color scale"}
              </Tooltip>
            }
          >
            <Button
              className="lock-button"
              onClick={() => setIsLocked(!isLocked)}
            >
              <img
                src={isLocked ? "/assets/lock.png" : "/assets/unlock.png"}
                alt={isLocked ? "Locked" : "Unlocked"}
                style={{ width: "20px", height: "20px" }}
              />
            </Button>
          </OverlayTrigger>
        )}
      </div>
    </div>
  );
};

export default LegendDiscrete;
