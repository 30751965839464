import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import styles from "./Map.module.css";

export default function LLCForm() {
  return (
    <Typography variant="body2" className={styles.llcform}>
      {'Copyright © '}
      <Link color="inherit" href="https://ptweather.com/">
        PTWeather LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'. (Beta Version. Free Trial Period)'}
    </Typography>
  );
}
