import React from "react";
import "./TermsOfUse.css";
import { Link } from "react-router-dom";

function Terms() {
  return (
    <div id="terms" className="terms">
      <Link to="/">
        <img
          className="aboutimage1"
          src="./assets/forecast/Cloudy.svg"
          alt="Weather Icon"
        />
      </Link>
      <div className="about-content" style={{ marginTop: "20px" }}></div>
      <h1>Terms of Use</h1>
      <ol className="terms-list">
        <li>
          <strong>Acceptance of Terms</strong>
          <p>
            By accessing and using PTWeather LLC's website at ptweather.com
            ("we", "us", or "our"), you agree to be bound by these Terms of Use
            ("Terms"). If you do not agree to these Terms, please do not use our
            services.
          </p>
        </li>
        <li>
          <strong>Changes to Terms</strong>
          <p>
            We reserve the right to modify or replace these Terms at any time at
            our sole discretion. It is your responsibility to check the Terms
            periodically for changes. Your continued use of ptweather.com
            following the posting of any changes to the Terms constitutes
            acceptance of those changes.
          </p>
        </li>
        <li>
          <strong>Use of Website</strong>
          <p>
            You agree to use ptweather.com and it's systems only for lawful
            purposes and in a way that does not infringe the rights of,
            restrict, or inhibit anyone else's use and enjoyment of the website.
            You may not use the website to query weather data for any unlawful
            or prohibited purpose.
          </p>
        </li>
        <li>
          <strong>Intellectual Property</strong>
          <p>
            All content included on ptweather.com, such as text, graphics,
            logos, images, as well as the compilation thereof, and any weather
            data queried through our user interface, is the property of
            PTWeather LLC or its content suppliers and protected by copyright
            and other laws that protect intellectual property and proprietary
            rights.
          </p>
        </li>
        {/* <li>
          <strong>User Account</strong>
          <p>
            If you create an account on ptweather.com, you are responsible for
            maintaining the security of your account, and you are fully
            responsible for all activities that occur under the account and any
            other actions taken in connection with it.
          </p>
        </li> */}
        <li>
          <strong>Limitation of Liability</strong>
          <p>
            In no event will PTWeather LLC, nor its directors, employees,
            partners, agents, suppliers, or affiliates, be liable for any
            indirect, incidental, special, consequential or punitive damages,
            including without limitation, loss of profits, data, use, goodwill,
            or other intangible losses, resulting from your access to or use of
            or inability to access or use the website, or any conduct or content
            of any third party on the website.
          </p>
        </li>
        {/* <li>
          <strong>Governing Law</strong>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of [Your Country/State], without regard to its conflict of law
            provisions.
          </p>
        </li> */}
      </ol>
    </div>
  );
}

export default Terms;
