import React from 'react';
import { Form } from 'react-bootstrap';

import styles from "../SideForm.module.css";

const DataStats = ({ stats }) => {
    return (
        <div className={styles.stats_container}>
            <Form.Label>
                min:{"  "}
                <input
                    type="text"
                    value={stats[0]}
                    readOnly={true}
                    className={styles.statsbox}
                />
            </Form.Label>
            <Form.Label>
                max:{"  "}
                <input
                    type="text"
                    value={stats[1]}
                    readOnly={true}
                    className={styles.statsbox}
                />
            </Form.Label>
            <Form.Label>
                avg:{"  "}
                <input
                    type="text"
                    value={stats[2]}
                    readOnly={true}
                    className={styles.statsbox}
                />
            </Form.Label>
        </div>
    );
};

export default DataStats;
