import styles from "../SideForm.module.css";
import React, { useEffect } from "react";

export default function ParameterDropDown({
  prefix = "",
  selectedModel,
  register,
  setValue,
  activeTabKey,
  parameter,
  setParameter,
}) {
  const getAggregationType = (optionValue) => {
    if (["era5_monthly", "era5_daily", "gfs_daily"].includes(selectedModel)) {
      switch (optionValue) {
        case "temperaturemin":
          return isAggsTab ? "Min (Avg)" : " (Min)";
        case "temperaturemax":
          return isAggsTab ? "Max (Avg)" : " (Max)";
        case "temperature":
          return isAggsTab ? " (Avg)" : " (Avg)";
        case "temperatureanom":
          return isAggsTab ? " (Avg)" : " (Avg)";
        case "precipitation":
        case "snow":
        case "sleet":
        case "frzrain":
          return " (Total)";
        case "pressure":
          return " (Min)";
        case "wind":
        case "windgusts":
          return " (Max)";
        default:
          return " (Avg)";
      }
    } else if (isAggsTab) {
      switch (optionValue) {
        case "lightning":
        case "radar":
        case "wind":
        case "wind300":
        case "windgusts":
          return " (Max)";
        case "pressure":
          return " (Min)";
        case "precipitation":
        case "snow":
        case "sleet":
        case "hail":
        case "frzrain":
          return " (Total)";
        default:
          return " (Avg)";
      }
    }
    return "";
  };

  useEffect(() => {
    const options = getModelOptionsArray();
    const filteredOptions = filterOptions(options);
    // Reset the selected parameter to a default value when the model changes
    let defaultOption = "combined"; // Default value for most cases

    // Check for specific models that have different default values
    if (selectedModel === "gfs_daily") {
      defaultOption = "temperature";
    } else if (
      selectedModel === "reanalysis_24" ||
      selectedModel === "reanalysis_season"
    ) {
      defaultOption = "snow";
    } else if (
      activeTabKey === "aggs" ||
      activeTabKey === "graph" ||
      selectedModel === "era5_daily" ||
      selectedModel === "era5_monthly" ||
      selectedModel === "graphcastgfs"
    ) {
      defaultOption = "temperature";
    }

    // Check if the parameter is present in the filteredOptions
    const isParameterAvailable = filteredOptions.some(
      (option) => option.value === parameter
    );

    // Set the parameter to the default option if it's not available
    if (!isParameterAvailable) {
      setParameter(defaultOption);
      setValue(prefix + "parameter", defaultOption);
      // If it is available use it. the parameter holds the parameter across tabs
    } else {
      setValue(prefix + "parameter", parameter);
    }
  }, [
    selectedModel,
    setValue,
    prefix,
    activeTabKey,
    parameter,
    setParameter,
  ]);

  const isAggsTab = activeTabKey === "aggs";

  // Weather parameters that shouldn't be aggregated on should be put here. Those will not appear on the Aggs param dropdown.
  const filterOptions = (options) => {
    if (isAggsTab) {
      return options.filter(
        (option) =>
          !["kucheraratio", "cloudcover", "snowdepth", "combined"].includes(
            option.value
          )
      );
    }
    return options;
  };

  const getModelOptions = () => {
    const options = getModelOptionsArray();
    const filteredOptions = filterOptions(options);

    return filteredOptions.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
        {getAggregationType(option.value)}
      </option>
    ));
  };

  const getModelOptionsArray = () => {
    let options;
    switch (selectedModel) {
      case "gfs":
        options = [
          { value: "cloudcover", label: "Clouds" },
          { value: "frzrain", label: "Freezing Rain" },
          { value: "precipitation", label: "Precipitation" },
          { value: "combined", label: "Precip Type/Rate" },
          { value: "pressure", label: "Pressure" },
          { value: "sleet", label: "Sleet" },
          { value: "snow", label: "Snow" },
          { value: "snowdepth", label: "Snow Depth" },
          { value: "temperature", label: "Temperature" },
          { value: "wind", label: "Wind" },
          { value: "wind300", label: "Jetstream" },
          { value: "windgusts", label: "Wind Gusts" },
          // { value: "preciprate", label: "Precip Rate" },
          // { value: "snowkuchera", label: "Snow Kuchera" },
          // { value: "snowcat", label: "Snow Category" },
          // { value: "raincat", label: "Rain Cat" },
          // { value: "kucheraratio", label: "Kuchera Snow Ratio" },
        ];
        break;

      case "graphcastgfs":
        options = [
          { value: "precipitation", label: "Precipitation" },
          { value: "pressure", label: "Pressure" },
          { value: "temperature", label: "Temperature" },
          { value: "wind", label: "Wind" },
        ];
        break;

      case "gfs_daily":
        options = [
          { value: "frzrain", label: "Freezing Rain" },
          { value: "precipitation", label: "Precipitation" },
          { value: "pressure", label: "Pressure" },
          { value: "snow", label: "Snow" },
          { value: "sleet", label: "Sleet" },
          { value: "temperaturemin", label: "Temperature" },
          { value: "temperaturemax", label: "Temperature" },
          { value: "temperature", label: "Temperature" },
          { value: "temperatureanom", label: "Temp Anomaly" },
          { value: "wind", label: "Wind" },
          { value: "windgusts", label: "Wind Gusts" },
        ];
        break;

      case "era5_monthly":
        options = [
          { value: "precipitation", label: "Precipitation" },
          { value: "pressure", label: "Pressure" },
          { value: "seaice", label: "Sea Ice Cover" },
          { value: "seaiceanom", label: "Sea Ice Anomoly" },
          { value: "sst", label: "SST" },
          { value: "sstanom", label: "SST Anomoly" },
          { value: "snow", label: "Snow" },
          { value: "snowdepth", label: "Snow Depth" },
          { value: "temperature", label: "Temperature" },
        ];
        break;

      case "era5_daily":
        options = [
          { value: "precipitation", label: "Precipitation" },
          { value: "pressure", label: "Pressure" },
          { value: "snow", label: "Snow" },
          { value: "windgusts", label: "Wind Gusts" },
          { value: "temperature", label: "Temperature" },
          { value: "temperatureanom", label: "Temp Anomaly" },
        ];
        break;

      case "reanalysis_24":
        options = [
          { value: "snow", label: "Snow" },
          { value: "precipitation", label: "Precipitation" },
        ];
        break;

      case "reanalysis_season":
        options = [
          { value: "snow", label: "Snow" },
          { value: "precipitation", label: "Precipitation" },
        ];
        break;

      case "hrrr":
        options = [
          { value: "cloudcover", label: "Clouds" },
          { value: "lightning", label: "Lightning" },
          { value: "frzrain", label: "Freezing Rain" },
          { value: "temperature", label: "Temperature" },
          { value: "precipitation", label: "Precipitation" },
          { value: "combined", label: "Precip Type/Rate" },
          { value: "pressure", label: "Pressure" },
          { value: "radar", label: "Radar 1km" },
          { value: "sleet", label: "Sleet" },
          { value: "snow", label: "Snow" },
          { value: "snowdepth", label: "Snow Depth" },
          { value: "wind", label: "Wind" },
          { value: "windgusts", label: "Wind Gusts" },
          // { value: "hail", label: "Hail" },
          // { value: "raincat", label: "Rain Cat" },
        ];
        break;

      case "nam":
        options = [
          { value: "cloudcover", label: "Clouds" },
          { value: "frzrain", label: "Freezing Rain" },
          { value: "temperature", label: "Temperature" },
          { value: "precipitation", label: "Precipitation" },
          { value: "combined", label: "Precip Type/Rate" },
          { value: "pressure", label: "Pressure" },
          { value: "sleet", label: "Sleet" },
          { value: "snow", label: "Snow" },
          { value: "snowdepth", label: "Snow Depth" },
          { value: "wind", label: "Wind" },
          { value: "windgusts", label: "Wind Gusts" },
          // { value: "kucheraratio", label: "Kuchera Snow Ratio" },
          // { value: "raincat", label: "Rain Cat" },
        ];
        break;
      default:
        options = [];
    }
    return options;
  };

  const handleParameterChange = (e) => {
    const newParameter = e.target.value;
    setValue(prefix + "parameter", newParameter);
    setParameter(newParameter);
  };

  return (
    <select
      {...register(prefix + "parameter")}
      className={styles.dropdown_param}
      onChange={handleParameterChange}
      value={parameter}
    >
      {getModelOptions()}
    </select>
  );
}
