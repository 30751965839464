import React from 'react';

export default function ForecastModelDropDown({ setSelectedForecastModel }) {
  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedForecastModel(value);
  };

  return (
    <select onChange={handleChange}>
       <option value="gfs">GFS</option>
      <option value="nam">NAM</option>
      <option value="hrrr">HRRR</option>
    </select>
  );
}
